import React from 'react';
import App from './components/app/app';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './contexts/authProvider';

const container = document.getElementById('app');
const root = createRoot(container); 

root.render(
	<BrowserRouter>
		<AuthProvider>
			<App />
		</AuthProvider>
	</BrowserRouter>
);