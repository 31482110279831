// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
	display: flex;
    flex-direction: column-reverse;
    color: var(--primary-font-color);
    font-size: 0.95rem;
    background-color: var(--quaternary-color);
    border-radius: 0.25rem;
    padding: 0.25rem;
}

.list-container .row-wrapper {
	display: flex;
	position: relative;
    background-color: var(--background-color);
    border-radius: 0.25rem;
    margin: 0.5rem;
    padding: 0.25rem;
    align-items: center;
	justify-content: flex-start;
}

/* .list-container .row-wrapper > :nth-child(2), .list-container .row-wrapper > :nth-child(3) {
	flex-grow: 1;
} */

.list-container .row-wrapper > * {
	padding-right: 1rem;
}

.list-container .row-wrapper .remove.icon {
	position: absolute;
	right: 1rem;
}

.list-container .add-button-wrapper {
	display: flex;
}

.list-container .list-field .thumb {
    width: 3rem;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./public/components/list/list.css"],"names":[],"mappings":"AAAA;CACC,aAAa;IACV,8BAA8B;IAC9B,gCAAgC;IAChC,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;CACC,aAAa;CACb,kBAAkB;IACf,yCAAyC;IACzC,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;CACtB,2BAA2B;AAC5B;;AAEA;;GAEG;;AAEH;CACC,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,aAAa;AACd;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".list-container {\n\tdisplay: flex;\n    flex-direction: column-reverse;\n    color: var(--primary-font-color);\n    font-size: 0.95rem;\n    background-color: var(--quaternary-color);\n    border-radius: 0.25rem;\n    padding: 0.25rem;\n}\n\n.list-container .row-wrapper {\n\tdisplay: flex;\n\tposition: relative;\n    background-color: var(--background-color);\n    border-radius: 0.25rem;\n    margin: 0.5rem;\n    padding: 0.25rem;\n    align-items: center;\n\tjustify-content: flex-start;\n}\n\n/* .list-container .row-wrapper > :nth-child(2), .list-container .row-wrapper > :nth-child(3) {\n\tflex-grow: 1;\n} */\n\n.list-container .row-wrapper > * {\n\tpadding-right: 1rem;\n}\n\n.list-container .row-wrapper .remove.icon {\n\tposition: absolute;\n\tright: 1rem;\n}\n\n.list-container .add-button-wrapper {\n\tdisplay: flex;\n}\n\n.list-container .list-field .thumb {\n    width: 3rem;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
