// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#users-container {
	background-color: var(--background-secondary-color);
	padding-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./public/views/users/users.css"],"names":[],"mappings":"AAAA;CACC,mDAAmD;CACnD,kBAAkB;AACnB","sourcesContent":["#users-container {\n\tbackground-color: var(--background-secondary-color);\n\tpadding-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
