// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-wrapper {
	display: flex;
	justify-content: flex-end;
}

.version {
	margin: 0.5rem 1rem;
	padding: 0.35rem 1rem !important;
	border-color: transparent !important;
	font-size: 0.85rem;
	border-radius: 1.25rem;
}
.version:focus, .version option:checked {
	border-color: rgba(0,0,0,0.5) !important;
}

.version-mismatch td:not(.row-detail-cell, .action-cell), .version-mismatch button.remove {
    opacity: 0.25;
	pointer-events: none;
}

.general-container {
	padding: 0 0.25rem 0.25rem 0.25rem;
	background-color: var(--background-secondary-color);
	height: calc(100% - 10rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.general-container label, .general-container-inner .row-detail-fields span {
	font-size: 0.95rem;
    font-weight: bold;
}

.general-container .go-live-pan, .general-container-inner > * {
	margin: 0.5rem;
	padding: 0.5rem 2rem;
	background-color: var(--background-color);
	border-radius: 0.5rem;
}

.general-container .go-live-pan button {
	width: 6rem;
	font-size: 0.75rem;
}

.general-container-inner button:not(.icon, .image) {
	background-color: var(--button-tertiary-up-color) !important;
}
.general-container-inner button:not(.icon):active {
	background-color: var(--button-tertiary-down-color) !important;
}

.table-container > table {
	padding-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./public/views/elements/elements.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,yBAAyB;AAC1B;;AAEA;CACC,mBAAmB;CACnB,gCAAgC;CAChC,oCAAoC;CACpC,kBAAkB;CAClB,sBAAsB;AACvB;AACA;CACC,wCAAwC;AACzC;;AAEA;IACI,aAAa;CAChB,oBAAoB;AACrB;;AAEA;CACC,kCAAkC;CAClC,mDAAmD;CACnD,0BAA0B;IACvB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;CACC,kBAAkB;IACf,iBAAiB;AACrB;;AAEA;CACC,cAAc;CACd,oBAAoB;CACpB,yCAAyC;CACzC,qBAAqB;AACtB;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,4DAA4D;AAC7D;AACA;CACC,8DAA8D;AAC/D;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".version-wrapper {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n}\n\n.version {\n\tmargin: 0.5rem 1rem;\n\tpadding: 0.35rem 1rem !important;\n\tborder-color: transparent !important;\n\tfont-size: 0.85rem;\n\tborder-radius: 1.25rem;\n}\n.version:focus, .version option:checked {\n\tborder-color: rgba(0,0,0,0.5) !important;\n}\n\n.version-mismatch td:not(.row-detail-cell, .action-cell), .version-mismatch button.remove {\n    opacity: 0.25;\n\tpointer-events: none;\n}\n\n.general-container {\n\tpadding: 0 0.25rem 0.25rem 0.25rem;\n\tbackground-color: var(--background-secondary-color);\n\theight: calc(100% - 10rem);\n    overflow-x: hidden;\n    overflow-y: auto;\n}\n\n.general-container label, .general-container-inner .row-detail-fields span {\n\tfont-size: 0.95rem;\n    font-weight: bold;\n}\n\n.general-container .go-live-pan, .general-container-inner > * {\n\tmargin: 0.5rem;\n\tpadding: 0.5rem 2rem;\n\tbackground-color: var(--background-color);\n\tborder-radius: 0.5rem;\n}\n\n.general-container .go-live-pan button {\n\twidth: 6rem;\n\tfont-size: 0.75rem;\n}\n\n.general-container-inner button:not(.icon, .image) {\n\tbackground-color: var(--button-tertiary-up-color) !important;\n}\n.general-container-inner button:not(.icon):active {\n\tbackground-color: var(--button-tertiary-down-color) !important;\n}\n\n.table-container > table {\n\tpadding-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
