import { useRef, useState, useEffect, useContext } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import './app.css';
import '../../style.css';

import Signin from "../../views/signin/signin";
import Signup from "../../views/signin/signup";
import Reset from "../../views/signin/reset";
import Sidebar from "../sidebar/sidebar";
import MediaLibrary from "../../views/media-library/media-library";
import ApplicationLibrary from "../../views/application-library/application-library";
import Events from "../../views/events/events";
import Elements from "../../views/elements/elements";
import Builder from "../../views/builder/builder";
import Settings from "../../views/settings/settings";
import User from "../../views/user/user";

const App = () => {
	const { auth, setAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const headerMenus = [
		{name: 'Media Library', path: '/media-library'},
		{name: 'Application Library', path: '/application-library'},
		{name: 'Events', path: '/events'},
		// {name: 'Elements', path: '/elements'},
		{name: 'Builder', path: '/builder'},
		{name: 'Settings', path: '/settings'},
	];
	const [sideItems, setSideItems] = useState([]);
	const [curSideItem, setCurSideItem] = useState();
	const [isLoading, setIsLoading] = useState(false);
	// const [sidebarStatus, setSidebarStatus] = useState('');
	
	useEffect(() => {
		const vcode = new URLSearchParams(window.location.search).get('v');

		if (vcode) {
			navigate('/signin', {state: {vcode: vcode}});
			return;
		}

		location.pathname == '/' && checkSession('/media-library');

		// axios interceptor for the loading splash
		axios.interceptors.request.use((config) => {
			setIsLoading(true);
			return config;
		}, (err) => {
			setIsLoading(false);
			return Promise.reject(err);
		});
		axios.interceptors.response.use((res) => {
			setIsLoading(false);
			return res;
		}, (err) => {
			setIsLoading(false);
			return Promise.reject(err);
		});
	}, []);

	useEffect(() => {
		if (!/^\/(signup|reset)/.test(location.pathname)) {
			checkSession();
		}

		setCurSideItem();
		
	}, [location.pathname]);

	const checkSession = (to) => {
		axios.get('/cms')
		.then(res => {
			if (res.data && res.data.Email && res.data.Role) {
				setAuth({...res.data});
				if (to) {
					if (to == '/media-library' && res.data.MediaRestriction) {
						to = '/elements';
					}
					navigate(to);
				}
			} else {
				navigate('/signin', {state: {vcode: location.state?.vcode}});
			}
		})
		.catch(err => {
			console.log(err);
		});
	};

	const logout = () => {
		axios.get('/cms/logout')
		.then(res => {
			navigate('/signin');
		})
		.catch (err => {
			console.log('get error:', err);
		});
	};

	const loadElements = (items) => {
		if (items) {
			if (Array.isArray(items)) {
				setSideItems([...items]);
			} else if (typeof items == 'object') {
				setSideItems({...items});
			}
		}
	};

	const loadElement = (item) => {
		setCurSideItem(item);
	};

	const onSelectSideItem = (item) => {
		item = {...item};
		setCurSideItem(item);
	};

	const onAddSidebarItem = (item) => {
		// setSideItems([...sideItems, item]);
		setCurSideItem(item);
		// setSidebarStatus('adding_side_item');
	};

	const changeUserName = (name) => {
		setAuth({...auth, Name: name});
	}

	return (
		<div className={ `app-container ${location.pathname.substring(1)} ${isLoading ? 'loading' : ''}` }>
			<div className="header-container">
				<div className="indeterminate-progress-bar">
					<div className="indeterminate-progress-bar__progress"></div>
				</div>
				<div className="loading-bar" ></div>
				<div className="logo-pan">
					<img className="app-logo" src="../assets/logo2x.png" />
				</div>
				{ !/^\/(signin|signup)/.test(location.pathname) && 
				<div className="menu-pan">
					<div className="header-menu">
						{ headerMenus.filter(menu => {
							let result = false;
							switch (menu.name) {
								case 'Media Library':
									result = !auth.MediaRestriction;
								break;
								case 'Application Library':
									result = !auth.MediaRestriction;
								break;
								case 'Events':
									result = true;
								break;
								case 'Elements':
									result = true;
								break;
								case 'Builder':
									result = auth.Role == 3;
								break;
								case 'Settings':
									result = auth.Role >= 2;
								break;
							}
							return result;
						}).map((menu, i) => <Link key={ i } className={ location.pathname == menu.path ? 'active' : ''} to={ menu.path }>{menu.name}</Link>)	}
					</div>
					{ auth.Email && 
					<div className="logged-menu">
						<div className="profile">
							{ auth.Name }
							<Link to="/user"><button className="profile-btn" /></Link>
						</div>
						<div className="logout">
							<button className="logout-btn" onClick={logout} />
						</div>
					</div> }
				</div> }
			</div>
			<div className="body-container">
				{ !/^\/(signin|signup)/.test(location.pathname) && <div className="left-pan">
					<Routes>
						<Route path="/elements" element={ <Sidebar menus={ sideItems } onSelectSideItem={ onSelectSideItem } /> }/>
						<Route path="/builder" element={ <Sidebar menus={ sideItems } curMenu={ curSideItem } onSelectSideItem={ onSelectSideItem } onAddSidebarItem={ onAddSidebarItem }  /> }/>
						<Route path="*" element={ <Sidebar /> }/>
					</Routes>
				</div> }
				<div className="right-pan">
					<div className="content-container">
						<Routes>
							<Route path="/signin" element={ <Signin /> } />
							<Route path="/signup" element={ <Signup /> } />
							<Route path="/reset" element={ <Reset /> } />
							<Route path="/media-library" element={ <MediaLibrary /> } />
							<Route path="/application-library" element={ <ApplicationLibrary /> } />
							<Route path="/events" element={ <Events /> } />
							{/* <Route path="/elements" element={ <Elements loadElements={ loadElements } curElement={ curSideItem } /> } /> */}
							<Route path="/builder" element={ <Builder loadElements={ loadElements } loadElement={ loadElement } curElement={ curSideItem } /> } />
							<Route path="/settings" element={ <Settings /> } />
							<Route path="/user" element={ <User onChangeUser={ changeUserName } /> } />
						</Routes>
					</div>
				</div>
			</div>
		</div>
	)
}

export default App;