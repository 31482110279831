import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './dropdown.css';

const Dropdown = forwardRef((props, ref) => {
	const [opts, setOpts] = useState([]);
	const [curOpt, setCurOpt] = useState({});
	const [status, setStatus] = useState('');

	const dropdownRef = useRef();

	useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

	useEffect(() => {
		props.opts && setOpts(props.opts);
		setCurOpt(props.curOpt || null);
	}, [props.opts, props.curOpt]);

	useEffect(() => {
		props.status == 'reset' && setStatus('');
	}, [props.status]);

	const selectOpt = (opt) => {
		setCurOpt(opt);
		props.onSelectOpt && props.onSelectOpt(opt);
	};

	const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setStatus('');
        }
    };

	const closeDropdown = () => {
		setStatus('');
	};

	useImperativeHandle(ref, () => ({
        closeDropdown
    }));

	return (
		<div ref={ dropdownRef } className={ `dropdown-container ${props.className || ''} ${status}` }>
			<button	className="select-button" onClick={ () => { setStatus(status == 'expanded' ? '' : 'expanded') } }>
				<span className="icon"><img src={ props.icon } /></span>
				<span className="text">{ props.text }</span>
				<span className="arrow"></span>
			</button>
			<ul className="opts-wrapper">
				{ (props.children || []).map((opt, i) => <li className="opt" key={ i }>{ opt }</li>) }
			</ul>
		</div>
  	)
});

export default Dropdown;