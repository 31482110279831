// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textlist-container {
	display: flex;
    flex-direction: column-reverse;
	font-size: 0.95rem;
}

.textlist-container .row-wrapper {
	display: flex;
	align-items: center;
}`, "",{"version":3,"sources":["webpack://./public/components/textlist/textlist.css"],"names":[],"mappings":"AAAA;CACC,aAAa;IACV,8BAA8B;CACjC,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB","sourcesContent":[".textlist-container {\n\tdisplay: flex;\n    flex-direction: column-reverse;\n\tfont-size: 0.95rem;\n}\n\n.textlist-container .row-wrapper {\n\tdisplay: flex;\n\talign-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
