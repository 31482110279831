import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import './application-library.css';
import Table from "../../components/table/table";
import RowDetailMedia from "../../components/row_detail/row_detail_media";
import * as Helpers from '../../services/helpers';
import Popup from '../../components/popup/popup';
import Tabbar from '../../components/tabbar/tabbar';

const ApplicationLibrary = (props) => {
	const location = useLocation();
	const [tableRows, setTableRows] = useState([]);
	const popupConfRef = useRef();
	const defaultPageRows = useRef();

	const [tabs, setTabs] = useState([]);
	const [curTab, setCurTab] = useState({});

	const tableColumns = [
		{ header: '__checkall', width: '5%', className: 'checkall-cell' },
		{ header: 'Preview', width: '10%', className: 'cell first-col', renderFn: (row) => {
			let thumb = '../assets/logo2x.png';
			if (row.thumb) {
				thumb = '/proxy?f=library/thumb/' + row.thumb;
			}
			return <a href={`/proxy?f=library/${row.filename}`} download={row.filename}><img className="thumb" src={thumb} /></a>
		} },
		{ header: 'Type', width: '5%', className: 'cell icon-filetype', renderFn: (row) => Helpers.getAssetTypeIcon(row.filename), sortable: true, sortingFn: (r1, r2) => Helpers.getAssetType(r1.filename).localeCompare(Helpers.getAssetType(r2.filename)), searchFn: (row) => (Helpers.getAssetType(row.filename) + Helpers.getFileExtension(row.filename)) },
		{ header: 'Title', width: '25%', className: 'cell', renderFn: (row) => row.title, sortable: true, searchFn: (row) => row.title },
		{ header: 'Used In', width: '20%', className: 'cell', renderFn: (row) => (row.usage || []).map((e, i) => <div key={ i } className="item">{ e.element + '> ' + e.tab + '> ' + e.name }</div>), searchFn: (row) => (row.usage || []).map(e => e.name).join(), sortable: true },
		{ header: 'Last Modified', width: '10%', className: 'cell last-col', renderFn: (row) => Helpers.getMMDDYYYY(row.lastUpdate), sortable: true, sortingFn: (r1, r2) => { return r1.lastUpdate > r2.lastUpdate ? 1 : r1.lastUpdate < r2.lastUpdate ? -1 : 0}, sorted: 'des', searchFn: (row) => Helpers.getMMDDYYYY(row.lastUpdate) },
		{ header: '__action', width: '15%', className: 'action-cell' },
	];

	useEffect(() => {
		Helpers.getCollection('settings', '', '', settingsData => {
			const settings = settingsData[0] || {};
			defaultPageRows.current = settings.pageRowsInMedia === undefined ? 10 : parseInt(settings.pageRowsInMedia);
		});

		const _tabs = [{name: 'ALL'}, {name: 'Corporate'}, {name: 'Raytheon'}, {name: 'Collins Aerospace'}, {name: 'Pratt & Whitney'}];
		setTabs(_tabs);
		setCurTab(_tabs[0]);
	}, []);

	useEffect(() => {
		updateTable();
	}, [location.key]);

	useEffect(() => {
		console.log('___________tabeRows changed:', tableRows);
	}, [tableRows]);

	const updateTable = () => {
		Helpers.getCollection('media', '', '', data => {
			props.reloadMediaDic && props.reloadMediaDic();
			setTableRows((data.sort((a, b) => a.lastUpdate < b.lastUpdate ? 1 : a.lastUpdate > b.lastUpdate ? -1 : 0) || []).filter(row => !props.extensions || props.extensions.includes(Helpers.getFileExtension(row.filename))));
		});
	};

	const deleteRows = (rows = []) => {
		const oRowsLength = rows.length;
		rows = rows.filter(row => !row.usage || row.usage.length == 0);
		if (rows.length != oRowsLength) {
			popupConfRef.current?.open();
			return;
		}
		const data = new FormData();
		data.append('id', rows.map(row => row._id).join());
		data.append('multiple', 'true');
		axios.post('/db/deleteMedia', data)
		.then(res => {
			setTableRows(tableRows.filter(row => !rows.includes(row)));
			console.log('rows deleted!', rows);
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	const onSelectTab = (tab) => {
		setCurTab(tab);
	};

	return (
		<>
			<Popup ref={popupConfRef}>
				<h2 className="popup-header">Not able to delete</h2>
				<div className="popup-body">Media items cannot be deleted until all references to them are removed</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => {
						popupConfRef.current.close();
					}}>OK</button>
				</div>
			</Popup>
			<Tabbar tabs={ tabs } curTab = { curTab } onSelectTab={ onSelectTab } />
			<Table className="standard-table" searchable={ true } rows={ tableRows } columns={ tableColumns } pagination={ { size: defaultPageRows.current === undefined ? 10 : defaultPageRows.current, sizes: [5,10,20,50,100] } } deleteRows={ deleteRows } selectRows={ props.selectRows }>
				{ (row, action, renderAction, colspan, closeFn) => <RowDetailMedia colSpan={ colspan } updateTable={ updateTable } row={ row } action={ action } renderAction={ renderAction } closeFn={ closeFn } /> }
			</Table>
		</>
		
	)
}

export default ApplicationLibrary