import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import './signin.css';

const Signin = () => {
	const { setAuth } = useContext(AuthContext);
	const [username, setUsername] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const navigate = useNavigate();
	const location = useLocation();
	const vcode = location.state?.vcode && location.state.vcode !== 'null' ? location.state.vcode : '';

	if (vcode) {
		localStorage.mac = vcode;
	}

	useEffect(() => {
        setErrMsg('');
    }, [username, pwd]);

	const handleSubmit = e => {
        e.preventDefault();
		const formData = new FormData(e.target);
		axios.post('/cms/login', formData)
		.then(res => {
			if (res.data?.user) {
				setAuth({...res.data});
				navigate(res.data?.user.MediaRestriction ? '/elements' : '/media-library');
			} else if (res.data?.message) {
				setErrMsg(res.data.message);
			}
			
		})
		.catch (err => {
			console.log('post error:', err);
		});
	}
	
	return (
		<div className="signin-container">
			<div className="col-left">
				<div className="row">
					<img className="logo" src="../assets/logo_white2x.png" />
				</div>
				<div className="row">
					Content Management System
				</div>
			</div>
			<div className="col-right">
				<div className="err-msg" dangerouslySetInnerHTML={{__html: errMsg}} />
				<form id="login-box" onSubmit={handleSubmit} className="">
					<input id="username" type="text" name="un" autoFocus className="grid-item username" placeholder="Username..." value={username} onChange={(e) => setUsername(e.target.value.trim())} />
					<input id="password" type="password" name="pw" className="grid-item password" placeholder="Password..." value={pwd} onChange={(e) => setPwd(e.target.value)} />
					<input type="hidden" name="v" value={vcode} />
					<input id="mac" type="hidden" name="mac" value={localStorage.mac || ''} />
					<span className="grid-item forgot-pw"><Link to="/reset">Forgot Password</Link></span>
					<button id="submit" className="grid-item submit signin" type="submit">Sign In</button>
					<hr />
					<span className="grid-item need-an-account">Need an Account?</span>
					<Link to='/signup' className="grid-item goto-signup"><button className="signup">Sign Up</button></Link>
				</form>	
			</div>
			
		</div>
	)
};

export default Signin;