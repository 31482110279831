import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './popup.css';

const Popup = forwardRef((props, ref) => {
	const popup = useRef(null);
	
	const open = () => {
		popup.current?.showModal();
	};
	
	const close = () => {
		popup.current?.close();	
	};

	useImperativeHandle(ref, () => ({
		open, close	
	}));
	
	useEffect(() => {
	}, []);

	return (
		<dialog ref={popup} className="popup-dialog">
			{ props.children }
		</dialog>
  	)
});

export default Popup;