// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cms-container .cms-block-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--background-color);
}

.cms-container .cms-block {
	flex-grow: 1;
	margin: 0.25rem;
	padding: 1rem;
	border-radius: 0.5rem;
	font-size: 0.95rem;
	background-color: var(--background-secondary-color);
}

.cms-container .cms-block > span {
	display: block;
	font-weight: bold;
}

.cms-container .cms-block label {
	font-weight: normal;
}

.cms-container .cms-block-row {
	display: flex;
}

.cms-container .cms-block-row > *, .cms-container .col-item-wrapper > * {
	margin-left: 0.25rem;
}

.cms-container .row-wrapper .col-num {
	display: none;
}

.cms-container .cms-save-delete {
    margin: 1rem;
}

.cms-block-column-wrapper {
	display: flex;
	flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./public/views/cms/cms.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,cAAc;IACX,eAAe;IACf,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,aAAa;CACb,qBAAqB;CACrB,kBAAkB;CAClB,mDAAmD;AACpD;;AAEA;CACC,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,aAAa;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".cms-container .cms-block-wrapper {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tmargin: 0.5rem;\n    padding: 0.5rem;\n    border-radius: 0.5rem;\n    background-color: var(--background-color);\n}\n\n.cms-container .cms-block {\n\tflex-grow: 1;\n\tmargin: 0.25rem;\n\tpadding: 1rem;\n\tborder-radius: 0.5rem;\n\tfont-size: 0.95rem;\n\tbackground-color: var(--background-secondary-color);\n}\n\n.cms-container .cms-block > span {\n\tdisplay: block;\n\tfont-weight: bold;\n}\n\n.cms-container .cms-block label {\n\tfont-weight: normal;\n}\n\n.cms-container .cms-block-row {\n\tdisplay: flex;\n}\n\n.cms-container .cms-block-row > *, .cms-container .col-item-wrapper > * {\n\tmargin-left: 0.25rem;\n}\n\n.cms-container .row-wrapper .col-num {\n\tdisplay: none;\n}\n\n.cms-container .cms-save-delete {\n    margin: 1rem;\n}\n\n.cms-block-column-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
