import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './dev.css';
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import * as Helpers from '../../services/helpers';
import Table from "../../components/table/table";
import RowDetailDev from "../../components/row_detail/row_detail_dev";

const Dev = (props) => {
	const location = useLocation();
	const { auth } = useContext(AuthContext);
	const [tableRows, setTableRows] = useState([]);

	const colNameRef = useRef();
	const propNameRef = useRef();
	const colNameforRenameRef = useRef();
	const propNameCurRef = useRef();
	const propNameNewRef = useRef();

	const tableColumns = [
		// { header: '__checkall', width: '5%', className: 'checkall-cell' },
		{ header: 'Collections', width: '75%', className: 'cell first-col last-col', renderFn: (row) => row.Name, sortable: true },
		{ header: '__action', width: '25%', className: 'action-cell' },
	];
	
	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		axios.get('/dev/tools')
		.then(res => {
			console.log('_____res.data.dev/tools:', res.data);
			setTableRows(_massageRow([...res.data.collections]));
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	const _massageRow = (rows = []) => {
		let newRow;
		return rows.map((row, i) => {
			newRow = {};
			newRow._id = 'r' + Date.now() + i;
			newRow.Name = row;
			return newRow;
		});
	};

	const deleteRow = (rows = []) => {
		if (rows.length == 0) {
			return;
		}

		axios.get('/dev/collectionRemoval?deleter=' + rows[0].Name)
		.then(res => {
			const _rows = tableRows.filter(row => row.Name != rows[0].Name);
			setTableRows(_rows);
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	const dropProperty = () => {
		const colName = colNameRef.current?.value;
		const propName = propNameRef.current?.value;

		if (!colName || !propName) {
			return;
		}

		axios.get('/dev/collectionRemoval?collection=' + colName + '&property=' + propName)
		.then(res => {
			loadData();
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	const renameProperty = () => {
		const colName = colNameforRenameRef.current?.value;
		const propNameOld = propNameCurRef.current?.value;
		const propNameNew = propNameNewRef.current?.value;

		if (!colName || !propNameOld || !propNameNew) {
			return;
		}

		axios.get('/dev/massPropertyRename?collection=' + colName + '&oldName=' + propNameOld + '&newName=' + propNameNew)
		.then(res => {
			loadData();
		})
		.catch(err => {
			console.log('Error:', err);
		});
	}
	
	return (
		<div className="dev-container">
			<div className="messing-with-db-container">
				<div className="messing-with-db-wrapper">
					<span>MESSING WITH DATABASE</span>
					<div className="messing-with-db dev-drop-wrapper">
						<select ref={ colNameRef } name="collection">
							{ tableRows.map(row => 
							<option key={ row._id }>{ row.Name }</option>) }
						</select>
						<input ref={ propNameRef } type="text" name="property" placeholder="Property Name(Comma Delimit)..." />
						<button className="tertiary" onClick={ dropProperty }>Drop Property</button>
					</div>
					<div className="messing-with-db dev-rename-wrapper">
						<select ref={ colNameforRenameRef } name="collection">
							{ tableRows.map(row => 
							<option key={ row._id }>{ row.Name }</option>) }
						</select>
						<input ref={ propNameCurRef } type="text" name="oldName" placeholder="Current Name..." />
						<input ref={ propNameNewRef } type="text" name="newName" placeholder="New Name..." />
						<button className="tertiary" onClick={ renameProperty }>Rename Property</button>
					</div>
				</div>
			</div>

			<Table className="standard-table" rows={ tableRows } columns={ tableColumns } deleteRows={ deleteRow }>
				{ (row, action, renderAction, colspan, closeFn) => 
				<RowDetailDev colSpan={ colspan } row={ row } action={ action } renderAction={ renderAction } closeFn={ closeFn } /> }
			</Table>
		</div>
		
	)
};

export default Dev;