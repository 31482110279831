import { useRef, useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import './sidebar.css';

const Sidebar = (props) => {
	const [items, setItems] = useState([]);
	const [curItem, setCurItem] = useState();
	const [status, setStatus] = useState('');

	const location = useLocation();

	useEffect(() => {
		setCurItem();
	}, [location.pathname]);
	
	useEffect(() => {
		let _items = [];
		if (Array.isArray(props.menus)) {
			_items = props.menus;
		} else {
			for (const key in props.menus) {
				_items.push(props.menus[key]);
			}
		}
		_items.sort((a, b) => a.Name.localeCompare(b.Name));
		setItems(_items);
		!props.curMenu && props.onSelectSideItem && selectSideItem(_items[0]);
		setStatus('');

		console.log('+++++++++++_tems:', _items);
	}, [props.menus]);

	useEffect(() => {
		props.curMenu && props.curMenu._id != curItem?._id && props.onSelectSideItem && selectSideItem(props.curMenu);
		console.log('~~~~~~~~~~props.curMenu:', props.curMenu);
	}, [props.curMenu]);

	const selectSideItem = (item) => {
		console.log('#_#_#_#_#_#_#item:', item);
		props.onSelectSideItem(item);
		setCurItem(item);
	};

	const addSidebarItem = (item) => {
		const _items = [...items];
		_items.push(item);
		setItems(_items);
		setCurItem(item);
		setStatus('adding');
		props.onAddSidebarItem(item);
	};

	const cloneSidebarItem = (item) => {
		try {
			item = JSON.parse(JSON.stringify(item));
			item.Name += '_Copy';
			delete item._id;
			addSidebarItem(item);
		} catch (err) {
			console.log('Error:', err);
		}
	};

	return (
		<div className={ `sidebar-container ${props.onAddSidebarItem ? 'editable' : ''} ${status == 'adding' ? 'adding' : ''}` }>
			{ curItem && items.map((item, i) => <div className={ `sidebar-item-wrapper ${ curItem._id == item._id ? 'active' : '' }` } key={ i }><div className="sidebar-item" onClick={ () => selectSideItem(item) }>{ item.Name }</div>{ item._id && <button className="image clone" onClick={ () => cloneSidebarItem(item) } /> }</div>) }
			{ props.onAddSidebarItem && status != 'adding' &&
			<button className="add image" onClick={ () => addSidebarItem({Name: 'New_Element', extensions: {}, hotspots: [], shortcuts: [], tabOrder: [], tabs: {}, versions: ['Default']}) } /> }
		</div>
  	)
};

export default Sidebar;