import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation, navigate } from "react-router-dom";
import axios from 'axios';
import './signup.css';

const Signup = () => {
	const [name, setName] = useState('');
	const [username, setUsername] = useState('');
	const [pwd, setPwd] = useState('');
	const [pwd2, setPwd2] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const navigate = useNavigate();
	
	useEffect(() => {
        setErrMsg('');
    }, [name, username, pwd, pwd2]);

	const handleSubmit = e => {
        e.preventDefault();
		const formData = new FormData(e.target);
        axios.post('/cms/signup', formData)
		.then(res => {
			res.data?.message && setErrMsg(res.data.message);
		})
		.catch (err => {
			console.log('post error:', err);
		});
	}
	
	return (
		<div className="signup-container">
			<div className="col-left">
				<div className="row">
					<img className="logo" src="../assets/logo_white2x.png" />
				</div>
				<div className="row">
					Content Management System
				</div>
			</div>
			<div className="col-right">
				<div className="err-msg" dangerouslySetInnerHTML={{__html: errMsg}} />
				<form id="signup-box" onSubmit={handleSubmit}>
					<input type="text" name="Name" autoFocus className="grid-item name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
					<input type="text" name="Email" className="grid-item username" value={username} onChange={(e) => setUsername(e.target.value.trim())}placeholder="Username (valid email address)" />
					<input type="password" name="Pwd" className="grid-item password" value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder="Password" />
					<input type="password" name="Pwd2" className="grid-item password2" value={pwd2} onChange={(e) => setPwd2(e.target.value)} placeholder="Re-type Password" />
					<hr />
					<button className="grid-item go-back primary" onClick={e => { navigate(-1) }}>Back</button>
					<button type="submit" className="grid-item submit primary" value="Sign Up">Sign Up</button>
				</form>
			</div>
		</div>
	)
};

export default Signup;