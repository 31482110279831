import { useRef, useState, useEffect } from 'react';
import './row_detail.css';
import * as Helpers from '../../services/helpers'; 
import axios from 'axios';
import Popup from '../popup/popup';

const RowDetail = (props) => {
	const [isCustomThumb, setIsCustomThumb] = useState(false);
	const [title, setTitle] = useState('');
	const [files, setFiles] = useState([]);
	const [extensions, setExtensions] = useState([]);
	const [thumb, setThumb] = useState();
	const [progress, setProgress] = useState(0);
	const [popupMsg, setPopupMsg] = useState('');
	const [status, setStatus] = useState('');
	
	const popupConfRef = useRef();
		
	useEffect(() => {
		setTitle(props.row?.title || '');
		axios.get('/cms/media')
		.then(res => {
			setExtensions(res.data.extensions);
		})
		.catch(err => {
			console.log('Error:', err);
		});
	}, []);

	const checkAutoThumb = (val) => {
		setIsCustomThumb(val == 'no');
	};

	const handleSelectedFiles = (e) => {
		const _files = [];
		const typeErrors = [];
		for (const file of e.target.files) {
			_files.push(file);
			if (!extensions.includes(Helpers.getFileExtension(file.name))) {
				typeErrors.push(file.name);
			}
		}
		
		setPopupMsg(
			<>
				<p>The following do not match the correct file type(s) of - { extensions.join(', ') }:</p>
				<p dangerouslySetInnerHTML={ {__html: typeErrors.join('<br />')} }></p>
			</>
		);

		if (typeErrors.length == 0) {
			setFiles(_files);
		} else {
			e.target.value = null;
			popupConfRef.current?.open();
		}
	};

	const handleSelectedThumb = (e) => {
		const imgExts = ['jpg', 'jpeg', 'png', 'gif'];
		const allowedExts = imgExts.filter(ext => extensions.includes(ext));

		setPopupMsg(
			<>
				<p>The following do not match the correct file type of - { allowedExts.join(', ') }:</p>
				<p>{ e.target.files[0]?.name }</p>
			</>
		);

		if (allowedExts.includes(Helpers.getFileExtension(e.target.files[0]?.name))) {
			setThumb(e.target.files[0]);
		} else {
			e.target.value = null;
			popupConfRef.current?.open();
		}
	};

	const saveMedia = () => {
		if (!props.row?._id && !title && files.length == 0) {
			return;
		}
		let _progress = 0;
		let data = new FormData();
		for (const file of files) {
			data.append('files', file, file.name);
		}
		data.append('autothumb', !isCustomThumb);
		data.append('thumb', thumb || '');
		data.append('title', title || '');
		data.append('medId', props.row?._id || '');
		data.append('usage', JSON.stringify(props.row?.usage || []));
		
		const conf = {
			onUploadProgress: (e) => {
				const { loaded, total } = e;
				_progress = loaded / total;
				setProgress(_progress);
			},
			headers: {
				'Content-Type': 'multipart/form-data'
			},
		};

		setStatus('updating');

		axios.post('/db/saveMedia', data, conf)
		.then(res => {
			props.updateTable();
		})
		.catch(err => {
			console.log('Error:', err);
		})
		.finally(() => {
			setStatus('');
			props.closeFn();
		});
	};

	return (
		<>
			<td className='checkall-cell'>{ props.action == 'EDIT' && props.renderAction('__checkall_tbody', props.row) }</td>
			<td className="cell row-detail-cell" colSpan={ props.colSpan }>
				<div className="progress-bar" style={ {width: Math.floor(progress * 100) + '%'} } />
				<div className={ `row-detail-fields ${status}` }>
					<Popup ref={popupConfRef}>
						<h2 className="popup-header">File type mismatch</h2>
						<div className="popup-body">{ popupMsg }</div>
						<div className="popup-footer">
							<button className="primary" onClick={ () => popupConfRef.current.close() }>OK</button>
						</div>
					</Popup>
					<div className="field-wrapper">
						<span>Title</span>
						<input className="input-title" type="text" value={ title } onChange={ (e) => { setTitle(e.target.value) } } readOnly={ files.length > 1 ? true : false } />
					</div>
					<div className="field-wrapper">
						<span>Media</span>
						<input type="file" multiple={ props.action=='ADD' } onChange={ handleSelectedFiles } />
					</div>
					{ files.length <= 1 && 
					<div className="field-wrapper">
						<span>Auto Thumbnail</span>
						<div>
							<label><input name="autothumb" type="radio" value="yes" checked={ !isCustomThumb } onChange={ e => checkAutoThumb(e.target.value) } />Yes</label>
							<label><input name="autothumb" type="radio" value="no" onChange={ e => checkAutoThumb(e.target.value) } />No</label>
						</div>
					</div> }
					{ isCustomThumb && 
					<div className="field-wrapper fade-in">
						<span>Custom Thumbnail</span>
						<input type="file" name="mthumb" onChange={ handleSelectedThumb } />
					</div> }
				</div>
			</td>
			<td className={ `action-cell ${status}` }>
				<div className="row-detail-save-btns">
					<button className="primary" onClick={ saveMedia }>{ props.action == 'EDIT' ? 'Save' : 'Add' }</button>
					<button className="primary" onClick={ props.closeFn }>Cancel</button>
				</div>
			</td>
		</>
  	)
};

export default RowDetail;