import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import './settings.css';
import AuthContext from "../../contexts/authProvider";
import * as Helpers from '../../services/helpers';
import Tabbar from '../../components/tabbar/tabbar';
import Cms from "../cms/cms";
import Users from "../users/users";
import Dev from "../dev/dev";

const Settings = (props) => {
	const { auth } = useContext(AuthContext);
	const [tabs, setTabs] = useState([]);
	const [curTab, setCurTab] = useState({});

	useEffect(() => {
		const _tabs = [{name: 'CMS'}, {name: 'Users'}, {name: 'Dev'}];
		
		setTabs(_tabs.filter(tab => !/CMS|Dev/.test(tab.name) || auth.Role > 2));
		setCurTab(_tabs[0]);
	}, []);

	const onSelectTab = (tab) => {
		setCurTab(tab);
	};
	
	return (
		<div className="settings-container">
			<Tabbar tabs={ tabs } curTab = { curTab } onSelectTab={ onSelectTab } /> 
			{ curTab.name == 'CMS' && <Cms /> }
			{ curTab.name == 'Users' && <Users /> }
			{ curTab.name == 'Dev' && <Dev /> }
		</div>
	)
};

export default Settings;