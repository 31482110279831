import { useRef, useState, useEffect, useContext } from 'react';
import './row_detail.css';
import * as Helpers from '../../services/helpers'; 
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import Popup from '../popup/popup';

const RowDetail = (props) => {
	const { auth, setAuth } = useContext(AuthContext);
	const [progress, setProgress] = useState(0);
	const [popupMsg, setPopupMsg] = useState('');
	const [status, setStatus] = useState('');
	const [user, setUser] = useState({});
	
	const popupErrorRef = useRef();
		
	useEffect(() => {
		setUser(props.row || {Validatable: false, Elements: []});
		console.log('Elemets:', props.elements);
	}, []);

	const handleFormChange = (e) => {
		const _user = { ...user };
		let idx;
		if (e.target.name == 'Elements') {
			_user.Elements = _user.Elements || [];
			idx = _user.Elements.indexOf(e.target.value);
			if (idx > -1) {
				_user.Elements.splice(idx, 1);
			} else {
				_user.Elements.push(e.target.value);
			}
		} else if (e.target.name == 'Validatable' || e.target.name == 'SkipValidation' || e.target.name == 'MediaRestriction') {
			_user[e.target.name] = e.target.value === 'false' ? false : true;
		} else {
			_user[e.target.name] = e.target.value;
		}
		
		setUser(_user);
	}

	const saveUser = e => {
		if (!user || !user.Email || !user.Name || !user.Password) {
			return;
		}
		setStatus('updating');
        console.log('handleSubmit:', e);
		axios.post('/db/saveUser', user)
		.then(res => {
			props.updateTable();
			props.closeFn();
		})
		.catch(err => {
			console.log('Error:', err);
			setPopupMsg(<>
				{ (err.response?.data || 'Unknown error: Please try again').split('\n').map((msg, i) => <p key={ i }>{ msg }</p>)}
			</>);
			popupErrorRef.current?.open();

		})
		.finally(() => {
			setStatus('');
		});
	}

	return (
		<>
			<td className="cell row-detail-cell" colSpan={ props.colSpan }>
				<div className="progress-bar" style={ {width: Math.floor(progress * 100) + '%'} } />
				<div className={ `row-detail-fields ${status}` }>
					<Popup ref={popupErrorRef}>
						<h2 className="popup-header">Error</h2>
						<div className="popup-body">{ popupMsg }</div>
						<div className="popup-footer">
							<button className="primary" onClick={ () => popupErrorRef.current.close() }>OK</button>
						</div>
					</Popup>
					{ user && user._id && <input name="_id" type="hidden" value={ user._id } readOnly /> }
					<div className="field-wrapper">
						<span>Username</span>
						<input className="textInput" name="Email" type="text" value={ user.Email || '' } onChange={ handleFormChange } />
					</div>
					<div className="field-wrapper">
						<span>Password</span>
						<input className="textInput" name="Password" type="password" value={ user.Password || '' } onChange={ handleFormChange } />
					</div>
					<div className="field-wrapper">
						<span>Name</span>
						<input className="textInput" name="Name" type="text" value={ user.Name || '' } onChange={ handleFormChange } />
					</div>
					<div className="field-wrapper">
						<span>Role</span>
						<select className="dropdown" name="Role" value={ user.Role } onChange={ handleFormChange }>
							<option value="">Choose One</option>
							{ auth.Role == 3 && <option id="3" value="3">Dev</option> }
							<option id="2" value="2">Admin</option>
							<option id="1" value="1">User</option>
							<option id="0" value="0">Inactive</option>
						</select>
					</div>
					{ auth.Role >= 2 && user.Role >= 2 && 
					<div className="field-wrapper">
						<span>Validation Privilege</span>
						<div>
							<input name="Validatable" id="v-yes" type="radio" value="true" checked={ Boolean(user.Validatable) } onChange={ handleFormChange } />
							<label htmlFor="v-yes">Yes</label>
							<input name="Validatable" id="v-no" type="radio" value="false" checked={ !Boolean(user.Validatable) } onChange={ handleFormChange } />
							<label htmlFor="v-no">No</label>
						</div>
					</div> }
					{ props.elements && Object.keys(props.elements).length > 0 && 
					<div className="field-wrapper">
						<span>Elements</span>
						<div className="checkboxList" name="Elements">
							{ Object.keys(props.elements).map(eid => <div key={ eid } className="row">
								<label>
									<input type="checkbox" name="Elements" value={ eid } checked={ (user.Elements || []).includes(eid) } onChange={ handleFormChange } />
									<span id={ eid }>{ props.elements[eid] }</span>
								</label>
							</div>) }
						</div>
					</div> }
					{ auth.Role == 3 && user.Role == 1 &&
					<div className="field-wrapper">
						<span>Media Library Restriction</span>
						<div>
							<input name="MediaRestriction" id="media-restriction-yes" type="radio" value="true" checked={ Boolean(user.MediaRestriction) } onChange={ handleFormChange } />
							<label htmlFor="media-restriction-yes">Yes</label>
							<input name="MediaRestriction" id="media-restriction-no" type="radio" value="false" checked={ !Boolean(user.MediaRestriction) } onChange={ handleFormChange } />
							<label htmlFor="media-restriction-no">No</label>
						</div>
					</div> }
					{ auth.Role == 3 &&
					<div className="field-wrapper">
						<span>Skip Validation</span>
						<div>
							<input name="SkipValidation" id="skip-validation-yes" type="radio" value="true" checked={ Boolean(user.SkipValidation) } onChange={ handleFormChange } />
							<label htmlFor="skip-validation-yes">Yes</label>
							<input name="SkipValidation" id="skip-validation-no" type="radio" value="false" checked={ !Boolean(user.SkipValidation) } onChange={ handleFormChange } />
							<label htmlFor="skip-validation-no">No</label>
						</div>
					</div>}
				</div>
			</td>
			<td className={ `action-cell ${status}` }>
				<div className="row-detail-save-btns">
					<button className="primary" onClick={ saveUser }>{ props.action == 'EDIT' ? 'Save' : 'Add' }</button>
					<button className="primary" onClick={ props.closeFn }>Cancel</button>
				</div>
			</td>
		</>
  	)
};

export default RowDetail;