import { useRef, useState, useEffect } from 'react';
import * as Helpers from '../../services/helpers'; 
import './table.css';
import Confirm from '../confirm/confirm';
import Pagination from '../pagination/pagination';
import Dropdown from '../dropdown/dropdown';

const Table = (props) => {
	const columns = props.columns;
	const colspan = columns && columns.length ? columns.length - (columns.reduce((cnt, col) => /^__/.test(col.header) ? cnt+1 : cnt, 0)) : 1;

	const [totalRows, setTotalRows] = useState([]);
	const [pageRows, setPageRows] = useState([]);
	const [page, setPage] = useState({size: 1, sizes: [], index: 0});
	const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);
	const [curRow, setCurRow] = useState();
	const [action, setAction] = useState();
	const [searchKey, setSearchKey] = useState('');
	const [dragHandleStatus, setDragHandleStatus] = useState('');
	
	const confirmDeleteRows = useRef();
	const tmpSearchKey = useRef();
	const justUpdatedRef = useRef();
	const exportDropdownRef = useRef();
	
	useEffect(() => {
		tableReset();
	}, [props.tableId]);

	useEffect(() => {
		setTotalRows([...props.rows]);
		if (props.pagination && props.pagination.size > 0) {
			setPage({...props.pagination, index: page.index || 0});
		} else { 
			setPage({size: props.rows.length, sizes: [], index: 0});
		}

		tmpSearchKey.current && search(tmpSearchKey.current);
	}, [props.rows]);

	useEffect(() => {
		console.log('page changed:', page);
		setPageRows(totalRows.filter((row, idx) => idx >= page.index * page.size && idx < (page.index + 1) * page.size));
	}, [page]);

	useEffect(() => {
		const isAllChecked = pageRows.reduce((result, pageRow) => {
			return Boolean(result && pageRow.__isChecked);
		}, pageRows.length > 0 ? true : false);
		setIsCheckAllChecked(isAllChecked);

		// to scroll to the row just updated
		setTimeout(() => {
			justUpdatedRef.current && justUpdatedRef.current.scrollIntoView({block: 'center', behavior: 'instant'});
		}, 1);
	}, [pageRows]);

	const tableReset = () => {
		setAction('');
		search('');
		tmpSearchKey.current = '';
	};

	const sortByColumn = (column) => {
		if (!column.sortable) {
			return;
		}

		if (!column.sorted) {
			columns.forEach(col => {
				col.sorted = '';
			});
			if (column.sortingFn) {
				totalRows.sort(column.sortingFn);
			} else {
				totalRows.sort((a, b) => {
					return column.renderFn(a) > column.renderFn(b) ? 1 : column.renderFn(a) < column.renderFn(b) ? -1 : 0;
				});
			}
			column.sorted = 'asc';
			console.log(column.header + ' sorted!!');
		} else {
			if (column.sorted == 'asc') {
				totalRows.reverse();
				column.sorted = 'des';
			} else if (column.sorted == 'des') {
				setTotalRows([...props.rows]);
				column.sorted = '';
			}
			columns.filter(col => col !== column).forEach(col => {
				col.sorted = '';
			});
		}
		setPage({...page});
	};

	const setPageSize = (num) => {
		setPage({...page, size: num, index: page.index < Math.ceil(totalRows.length / num) ? page.index : Math.floor(totalRows.length / num)});
	};

	const setPageIndex = (num) => {
		setPage({...page, index: Helpers.clamp(num, 0, Math.ceil(totalRows.length / page.size) - 1)});
	};

	const gotoPreviousPage = () => {
		setPage({...page, index: page.index > 0 ? page.index - 1 : 0});
	};

	const gotoNextPage = () => {
		setPage({...page, index: page.index < (Math.ceil(totalRows.length / page.size) - 1) ? page.index + 1 : page.index});
	};

	const search = (key) => {
		setSearchKey(key);
		tmpSearchKey.current = key;
		if (!key) {
			setTotalRows([...props.rows]);
			setPage({...page});
		} else {
			const rexp = new RegExp(key, 'i');
			let resultRows = [];

			for (const row of props.rows) {
				for (const col of columns.filter(col => col.searchFn)) {
					if (rexp.test(col.searchFn(row))) {
						resultRows.push(row);
						break;
					}
				}
			}
			console.log('search resultRows:', resultRows);
			setTotalRows(resultRows);
			setPage({...page, index: 0});
			selectAllRowsToggle(false);
		}
	};

	const selectAllRowsToggle = (val) => {
		setPageRows(pageRows.map(pageRow => {
			pageRow.__isChecked = val;
			return pageRow;
		}));
	};

	const selectRowToggle = (val, row) => {
		setPageRows(pageRows.map(pageRow => {
			if (pageRow._id == row._id) {
				pageRow.__isChecked = val;
			}
			return pageRow;
		}));
	};

	const renderAction = (type, row, column) => {
		let cell = <div></div>;
		switch (type) {
			case '__checkall_thead':
				cell = <input type="checkbox" checked={ !!isCheckAllChecked} onChange={e => selectAllRowsToggle(e.target.checked) } />
			break;
			case '__checkall_tbody':
				cell = <input type="checkbox" checked={ !!row.__isChecked } onChange={ e => selectRowToggle(e.target.checked, row) } />
			break;
			case '__dragme_thead':
				cell = <div />
			break;
			case '__dragme_tbody':
				cell = <button className="drag icon" onMouseDown={ dragIconTouchStartHandler }  onTouchStart={ dragIconTouchStartHandler } onMouseUp={ dragIconTouchEndHandler }  onTouchEnd={ dragIconTouchEndHandler } />
			break;
			case '__action_thead':
				cell = <button className="image add" onClick={ e => addRow() } />
			break;
			case '__action_tbody':
				cell = <><button className="image edit" onClick={ e => editRow(row) } /><button className="image remove" onClick={ e => deleteRow(row) } />{ column && column.customButton && <button className={ `image ${column.customButton.className}` } onClick={ e => column.customButton.fn(row) } /> }</>;
			break;
		}
		return cell;
	};

	const addRow = () => {
		console.log('addRow!!');
		setAction('ADD');
	};
	const editRow = (row) => {
		setAction('EDIT');
		setCurRow(row);
		console.log('editRow!!', row);
	};
	const deleteRow = (row) => {
		setAction('DELETE');
		setCurRow(row);
		confirmDeleteRows.current?.open();
	};
	const deleteRows = () => {
		confirmDeleteRows.current?.open();
	};
	const _deleteRows = (rows) => {
		props.deleteRows(rows);
		setCurRow();
	};

	const handleSeletedRows = (rows) => {
		// props.selectRows has priority over deleteRows
		if (props.selectRows) {
			props.selectRows(rows);
		} else if (props.deleteRows) {
			deleteRows();
		}
	};

	const dragStartHandler = (e, row) => {
		console.log(e.currentTarget);
		setCurRow(row);
	};

	const dropHandler = (row) => {
		const _rows = [...totalRows];
		const idx1 = _rows.indexOf(row);
		const idx2 = _rows.indexOf(curRow);

		if (idx1 != -1 && idx2 != -1) {
			_rows.splice(idx2, 1);
			_rows.splice(idx1, 0, curRow);
			setTotalRows(_rows);
			setPageRows(_rows);
			props.onDrop && props.onDrop(_rows);
		}
	};

	const dragOverHandler = (e, row) => {
		e.preventDefault();
	};

	const dragEndHandler = (e) => {
		setDragHandleStatus('');
	};

	const dragIconTouchStartHandler = (e) => {
		setDragHandleStatus('dragging');
	};

	const dragIconTouchEndHandler = (e) => {
		setDragHandleStatus('');
	};

	const checkedPageRows = pageRows.filter(row => row.__isChecked);

	const onFilterChange = (e) => {
		console.log('Filter changed:', e.target.value);
	};

	const doExport = (format) => {
		format = format.replace('To ', '');
		console.log('Exporting to:', format);
		if (exportDropdownRef.current) {
            exportDropdownRef.current.closeDropdown();
        }
	};

	return (
		<div className="table-container">
			<Confirm ref={ confirmDeleteRows } onYes={ curRow ? () => _deleteRows([curRow]) : () => _deleteRows(checkedPageRows) } />
			
			<table className={ props.className }>
				<thead>
					<tr className="tools-row">
						{ /^(__checkall|__dragme)/.test(props.columns?.[0]?.header) && <th></th> }
						<th className="search-delete-cell" colSpan={ colspan }>
							<div className="search-delete-wrapper">
								{ props.searchable ?
								<div className="search-wrapper">
									<input type="text" className="search" placeholder="Search..." value={ searchKey } onChange={ e => search(e.target.value) } />
									{ searchKey && <button className="clear-searchkey primary" onClick={ () => search('') }>Cancel</button> }
								</div> : <div></div> }
								{ props.filters && props.filters.length > 0 &&
								<Dropdown className="filter-dropdown" icon="../../assets/icon_filter2x.png" text="Filter">
									{ 
										props.filters.map((filter, i) => (
											<div className="filter-item" key={ i }>
												<span>{ filter.name }:</span>
												<select onChange={ onFilterChange }>
													{ filter.vals.map((val, j) => <option key={ j } value={ val }>{ val }</option>) }
												</select>
											</div>
										))
									}
								</Dropdown> }
								{ props.export &&
								<Dropdown ref={ exportDropdownRef } className="export-dropdown" icon="../../assets/icon_export2x.png" text="Export">
									{ 
										['To PPT', 'PDF', 'CSV'].map((format, i) => (
											<div className="export-item" key={ i }>
												<button onClick={ () => doExport(format) }>{ format }</button>
											</div>
										))
									}
								</Dropdown> }
								{ props.linkToPage &&
								<Dropdown className="link-dropdown" icon="../../assets/icon_share2x.png" text="Link to Page">
								</Dropdown> }
								{ checkedPageRows.length > 0 ? 
								<button className="secondary" onClick={ () => handleSeletedRows(checkedPageRows) }>
									{ (props.selectRows ? 'Select ' : 'Delete Selected ') + '(' + checkedPageRows.length + ')' }
								</button> : <div></div> }
							</div>
						</th>
						<th></th>
					</tr>
					<tr>
					{ columns.map((column, id) => (
						/^__/.test(column.header) ? 
						<th key={ id } className={ `${column.className || ''}` } style={ {width: column.width} }>
							{ renderAction(column.header + '_thead') }
						</th> :
						<th key={ id } className={ `${column.className || ''} ${column.sortable ? 'sortable ' + (column.sorted || 'non') : ''}` }  style={ {width: column.width} } onClick={ e => { sortByColumn(column) } }>
							{ column.header || null }
						</th>
					)) }
					</tr>
				</thead>
				<tbody>
					{ action == 'ADD' && 
					<tr className="row-detail inline-add fade-in">
						{ props.children(null, action, renderAction, colspan, () => {
							setTotalRows(totalRows.map(row => {
								delete row.__isChecked;
								return row;
							}));
							setAction('');
						}) }
					</tr> }
					{ (pageRows || []).map((row) => { 
					if (props.alterRow) {
						row = props.alterRow(row);
					} 
					return (
					<tr key={ row._id } className={ `row-detail ${row.__isChecked ? 'active' : ''} ${row.__className || ''} ${row.__isJustUpdated ? 'just-updated' : ''}` } { ...(row.__isJustUpdated && { ref: justUpdatedRef }) } { ...(dragHandleStatus == 'dragging' && { draggable: true, onDragStart: (e) => dragStartHandler(e, row), onDrop: () => dropHandler(row), onDragOver: e => dragOverHandler(e, row), onDragEnd: dragEndHandler }) }>
					{ action == 'EDIT' && curRow._id === row._id ? props.children(row, action, renderAction, colspan, () => setAction('')) : columns.map((column, i) => (
						<td key={ String(row._id) + i } className={ `${column.className || ''}` }>
							{ /^__/.test(column.header) ? renderAction(column.header + '_tbody', row, column) : column.renderFn(row) }
						</td>
					)) }
					</tr> ) }) }
				</tbody>
				<tfoot>
					<tr>
						{ props.columns?.[0]?.header == '__checkall' && <td></td> }
						<td colSpan={ colspan }>
							<div className="footer-cell">
								<div className="t-total" style={ {visibility: props.pagination ? 'visible' : 'hidden'} }>showing { Math.min(page.index * page.size + 1, totalRows.length) } to { (page.index + 1) * page.size > totalRows.length ? totalRows.length % page.size + page.index * page.size : (page.index + 1) * page.size } of { totalRows.length } entries</div>
								{ props.pagination && 
								<div className='pagination-wrapper'>
									<Pagination active={ page.index + 1 } total={ page.size > 0 ? Math.ceil(totalRows.length / page.size) : 0 } page={ page } step={ 1 } onClickHandler={ (pNum) => {
										const pIdx = pNum ? Number(pNum) - 1 : 0;
										setPageIndex(pIdx);
									} } setPageSize={ setPageSize } />
								</div> }
							</div>
						</td>
						<td></td>
					</tr>
				</tfoot>
			</table>
	  </div>
  	)
};

export default Table;