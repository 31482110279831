import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './confirm.css';
import Popup from '../popup/popup';

const Confirm = forwardRef((props, ref) => {
	const popupConfRef = useRef();

	const open = () => {
		popupConfRef.current?.open();
	};
	
	const close = () => {
		popupConfRef.current?.close();	
	};

	useImperativeHandle(ref, () => ({
		open, close
	}));

	useEffect(() => {
		
	}, []);

	return (
		<Popup ref={popupConfRef}>
			<h2 className="popup-header">Are you sure?</h2>
			<div className="popup-footer">
				<button className="primary" onClick={ () => { 
					props.onYes();
					popupConfRef.current?.close();
				} }>Yes</button>
				<button className="primary" onClick={ () => popupConfRef.current.close() }>Cancel</button>
			</div>
		</Popup>
  	)
});

export default Confirm;