import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './builder.css';
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import * as Helpers from '../../services/helpers';
import Tabbar from '../../components/tabbar/tabbar';
import TextList from "../../components/textlist/textlist";
import Confirm from '../../components/confirm/confirm';
import Popup from '../../components/popup/popup';
import Table from "../../components/table/table";
import RowDetailBuilder from "../../components/row_detail/row_detail_builder";

const Builder = (props) => {
	const location = useLocation();
	const { auth } = useContext(AuthContext);
	const [settings, setSettings] = useState({});
	const [element, setElement] = useState({});
	const [tabs, setTabs] = useState([]);
	const [curTab, setCurTab] = useState();
	const [curTabName, setCurTabName] = useState('');
	const [status, setStatus] = useState('');
	const [tabStatus, setTabStatus] = useState('');
	const [tabRefs, setTabRefs] = useState(null);
	const [tableRows, setTableRows] = useState([]);

	const elNameInputRef = useRef();
	const tabNameInputRef = useRef();
	
	const elementsRef = useRef();
	const confirmDeleteTab = useRef();
	const confirmDeleteEl = useRef();
	const popupErrDelete = useRef();
	const popupErrNamingEl = useRef();
	const popupErrNamingTab = useRef();
	const popupCheckRefs = useRef();

	const tableColumns = [
		{ header: '__dragme', width: '5%', className: 'dragme-cell' },
		{ header: 'Field Type', width: '16%', className: 'cell first-col', renderFn: (row) => row.type, sortable: true },
		{ header: 'Field Name', width: '20%', className: 'cell', renderFn: (row) => row.name, sortable: true },
		{ header: 'Width (%)', width: '10%', className: 'cell', renderFn: (row) => row.container?.width },
		{ header: 'Margin (CSS)', width: '12%', className: 'cell', renderFn: (row) => (row.container?.margin) },
		{ header: 'Display Type', width: '12%', className: 'cell', renderFn: (row) => row.container?.display },
		{ header: 'Table Sort', width: '10%', className: 'cell', renderFn: (row) => row.table },
		{ header: '__action', width: '15%', className: 'action-cell' },
	];

	const defaultVals = {
		type: 'Single-line Input', 
		container: {
			width: '100', 
			margin: '0 0 0 0', 
			display: 'block'
		},
		table: 'Hidden', 
		options: {
			'Input Type': 'text', 
			'Character Limit': 0, 
			'Default': ''
		}
	};
	
	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		// props.curElement && getElement(props.curElement);
		console.log('++++++++++props.curElement:', props.curElement);
		const _element = props.curElement;
		const _tabs = _getElementOrderedTabs(_element);

		setTabs(_tabs);
		setCurTab(null);
		setCurTabName('');
		setElement(_element);
		setStatus('');
		setTabStatus('reset');
	}, [props.curElement]);

	const _getElementOrderedTabs = (el) => {
		const _tabs = [];
		let _tab;
		for (const tabName of (el?.tabOrder || [])) {
			_tab = { ...el.tabs[tabName] };
			_tabs.push({ ..._tab, name: tabName });
		}
		return _tabs;
	};

	const loadData = (callback) => {
		Helpers.getCollection('settings', '', '', settingsData => {
			console.log('++++++getSettings:', settingsData);
			Helpers.getCollection('elements', '', '', elementsData => {
				console.log('-----getElements:', elementsData);
				props.loadElements(elementsData);
				elementsRef.current = elementsData;
				setSettings(settingsData[0] || {});

				if (typeof callback == 'function') {
					callback();
				}
			});
		});
	};

	const onSelectTab = (tab) => {
		if (!tab) {
			return;
		}
		console.log('......curTab:', tab, status);
		// status == 'adding_tab' && curTab !== tab && setStatus('');
		setCurTab(tab);
		setCurTabName(tab.name);
		setTableRows(_addIdtoFields(tab.fields));
	}

	const _addIdtoFields = (fields) => {
		return (fields || []).map((field, i) => {
			field._id = 'f' + Date.now() + i;
			return field;
		});
	};

	const onAddTab = (tab) => {
		const defaultField = {...defaultVals, name: 'Name', table: 'Shown'};
		const newTab = {...tab, data: {Permissions: 'All', Sort: ['last_update_time', -1], Visibility: '1'}, fields: _addIdtoFields([defaultField])};
		setCurTab(newTab);
		setCurTabName(tab.name);
		setTabs([...tabs, newTab]);
		setStatus('adding_tab');
		setTabStatus('');
		setTableRows(newTab.fields);
	}

	const cancelAddTab = () => {
		const _tabs = [...tabs];
		_tabs.pop();
		setTabs(_tabs);
		setCurTab();
		setCurTabName('');
		setStatus('');
		setTabStatus('reset');
	};

	const deleteTab = (confirmed) => {
		console.log('===========deleteTab:', curTab);

		searchForObjectReference(curTab.name, (refs) => {
			setTabRefs(refs);
			if (refs) {
				popupCheckRefs.current?.open();
			} else {
				if (!confirmed) {
					confirmDeleteTab.current?.open();
				} else {
					const _element = {...element};
					const _tabs = [...tabs];
					axios.get(`/dev/collectionRemoval?deleter=${element.Name}-${curTab.name}`)
					.then(res => {
						delete _element.tabs[curTab.name];
						_element.tabOrder.splice(_element.tabOrder.indexOf(curTab.name), 1);
						_tabs.splice(tabs.indexOf(curTab), 1);
					})
					.catch(err => {
						popupErrDelete.current?.open();
					})
					.finally(() => {
						setTabs(_tabs);
						setCurTab();
						setCurTabName('');
						setElement(_element);
						saveElement(_element);
					});
				}
			}
		});
	};

	const handleInputChange = (fieldName, value) => {
		const _element = { ...element };
		
		let type = '';
		let event = null;
		if (fieldName.target?.name && !value) {
			event = fieldName;
			type = event.target.type;
			value = event.target.value || '';
			fieldName = event.target.name;
		}

		
		if (curTab && curTab.name) {
			_element.tabs = _element.tabs || {};

			if (status == 'adding_tab') {
				_element.tabs[curTab.name] = {...curTab};
				delete _element.tabs[curTab.name].name;
			} else {
				_element.tabs[curTab.name] = _element.tabs[curTab.name] || {};
				_element.tabs[curTab.name].data = _element.tabs[curTab.name].data || {};
			}
		}
		
		switch(fieldName) {
			case 'Name':
				value = value.replace(/(\s|-)/g, '_');
				_element[fieldName] = value;
			break;
			case 'versions':
				value = value.flat();
				_element[fieldName] = value;
			break;
			case 'shortcuts':
				value = value.map(v => {
					return {Name: v[0], URL: v[1]};
				});
				_element[fieldName] = value;
			break;
			case 'hotspots':
				_element[fieldName] = _element[fieldName] || [];
				if (value == 'on') {
					_element[fieldName].push(curTabName);
				} else if (value == 'off') {
					_element[fieldName].splice(_element[fieldName].indexOf(curTabName), 1);
				}
			break;
			case 'extensions':
				_element[fieldName] = _element[fieldName] || {};

				const vals = value.split(':');

				if (event && event.target?.checked) {
					if (!_element[fieldName][vals[0]]) {
						_element[fieldName][vals[0]] = [];
					}
					_element[fieldName][vals[0]].push(vals[1]);
				} else {
					_element[fieldName][vals[0]].splice(_element[fieldName][vals[0]].indexOf(vals[1]), 1);
				}
			break;
			case 'tabOrder':
				_element[fieldName] = value;
				setTabs(_getElementOrderedTabs(_element));
				saveElement(_element);	// auto save whenever drop tab
			break;
			case 'tab-name':
				value = value.replace(/(\s|-)/g, '_');
				setCurTabName(value);			
			break;
			case 'tab-permissions':
				_element.tabs[curTab.name].data.Permissions = value;
			break;
			case 'tab-visibility':
				_element.tabs[curTab.name].data.Visibility = value;
			break;
			case 'tab-sort-name':
				_element.tabs[curTab.name].data.Sort = _element.tabs[curTab.name].data.Sort || [];
				_element.tabs[curTab.name].data.Sort[0] = value;
			break;
			case 'tab-sort-direction':
				_element.tabs[curTab.name].data.Sort = _element.tabs[curTab.name].data.Sort || [];
				_element.tabs[curTab.name].data.Sort[1] = value;
			break;
			case 'tab-fields':
				value = (value || []).map(val => { delete val._id; return val });
				_element.tabs[curTab.name].fields = value;
				setTableRows(_addIdtoFields(value));
				const _curTab = {...curTab};
				_curTab.fields = value;
				setCurTab(_curTab);
			break;
			default: 
				_element[fieldName] = value;

			// default:
			// 	if (type == 'checkbox') {
			// 		_element[fieldName] = _element[fieldName] || [];
			// 		const idx = _element[fieldName].indexOf(value);
			// 		if (idx != -1) {
			// 			_element[fieldName].splice(idx, 1);
			// 		} else {
			// 			_element[fieldName].push(value);
			// 		}
			// 	} else {
			// 		_element[fieldName] = value;
			// 	}
		}
		
		setElement(_element);
		// if (status == 'adding_tab') {
		// 	setCurTab(_element.tabs[curTab.name]);
		// }
	};
	
	const searchForObjectReference = (name, cb) => {
		if (!element) {
			return;
		}
		
		let results = {}, count = 0;
		let tab;
		for (const tabName in element.tabs) {
			tab = element.tabs[tabName];
			for(let field of tab.fields) {
				if(field.type == 'Object' && field.options.Type == name) {
					if(!results[tabName]) {
						results[tabName] = '';
					}
					results[tabName] += field.name;
					count++;
				}
			}
		}
		
		cb(count > 0 ? results : null);
	};

	const deleteElement = (confirmed) => {
		if (!confirmed) {
			confirmDeleteEl.current?.open();
		} else {
			axios.post('/db/delete', {collection: 'elements', id: element._id})
			.then(res => {
				loadData(() => {
					props.loadElement();
				});
			})
			.catch(err => {
				console.log('Error:', err);
			})
			.finally(() => {
			});
		}
	};

	const cancelAddElement = () => {
		props.loadElements(elementsRef.current);
		props.loadElement();
	};

	const saveElement = (_element = {...element}) => {
		_element.versions = _element.versions?.filter(v => v);
		_element.shortcuts = _element.shortcuts?.filter(v => v.Name || v.URL);
		_element.tabOrder = _element.tabOrder || [];

		const _curTabName = curTabName.trim();

		if (curTab && curTab.name && _curTabName) {
			if (status == 'adding_tab' || curTab.name != _curTabName) {  // in case of adding tab or renaming
				if (_element.tabOrder.indexOf(_curTabName) != -1) {
					popupErrNamingTab.current?.open();
					return;
				}

				let tabIdx = tabs.indexOf(curTab);
				const _tabs = [...tabs];
				const _curTab = {...curTab};
				_element.tabs = _element.tabs || {};
				_element.tabs[_curTabName] = _curTab;
				delete _element.tabs[_curTabName].name;
				if (status == 'adding_tab') {
					_element.tabOrder.push(_curTabName);
					if (curTab.name != _curTabName)	{
						delete _element.tabs[curTab.name];
					}

					tabIdx = _tabs.length - 1;
				} else {
					_element.tabOrder[_element.tabOrder.indexOf(curTab.name)] = _curTabName;
					delete _element.tabs[curTab.name];
				}

				_tabs[tabIdx] = curTab;
				_tabs[tabIdx].name = _curTabName;
								
				setTabs(_tabs);
				setCurTab(_tabs[tabIdx]);
				setCurTabName(_curTabName);
				setTabStatus('reset');
				setStatus('');
			}

			if (_element.tabs[_curTabName]) {
				const fields = [];
				let fieldCloned;
				for (const field of (_element.tabs[_curTabName]?.fields || [])) {
					fieldCloned = {...field};
					delete fieldCloned._id;
					fields.push(fieldCloned);
				}
				element.tabs[_curTabName].fields = fields;
			}
		}

		if (_element._id && curTab && !_curTabName) {
			popupErrNamingTab.current?.open();
			return;
		}

		if (!_element._id && (!_element.Name || (elementsRef.current || []).find(el => el.Name == _element.Name))) {
			popupErrNamingEl.current?.open();
			return;
		}

		axios.post('/dev/saveElement', {element: JSON.stringify(_element)})
		.then(res => {
			const cb = _element._id ? null : () => { props.loadElement({..._element, _id: res.data._id}) };
			loadData(cb);

			// !_element._id && loadData(() => { props.loadElement({..._element, _id: res.data._id}) });
		})
		.catch(err => {
			console.log('Error:', err);
		})
		.finally(() => {
		});
	};

	const deleteRow = (rows = []) => {
		if (rows.length == 0) {
			return;
		}

		const _element = {...element};
		const _curTab = {...curTab};
		const _tabs = [...tabs];
		const tabIdx = tabs.indexOf(curTab);
		_curTab.fields = curTab.fields.filter(field => field !== rows[0]);
		_element.tabs[curTab.name] = _curTab;
		_tabs[tabIdx] = _curTab;

		setElement(_element);
		setCurTab(_curTab);
		setTabs(_tabs);
		setTableRows(_curTab.fields);
	};

	const onDrop = (rows = []) => {
		if (rows.length == 0) {
			return;
		}

		const _element = {...element};
		const _curTab = {...curTab};
		const _tabs = [...tabs];
		const tabIdx = tabs.indexOf(curTab);
		_curTab.fields = rows;
		_element.tabs[curTab.name] = _curTab;
		_tabs[tabIdx] = _curTab;

		setElement(_element);
		setCurTab(_curTab);
		setTabs(_tabs);
		// setTableRows(_curTab.fields);
	};

	return (
		<div className={ `builder-container ${element && !element._id ? 'adding-element' : ''}` }>
			<Confirm ref={ confirmDeleteTab } onYes={ () => deleteTab(true) } />
			<Confirm ref={ confirmDeleteEl } onYes={ () => deleteElement(true) } />
			<Popup ref={popupErrDelete}>
				<h2 className="popup-header">Error</h2>
				<div className="popup-body">Could not delete</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => {
						popupErrDelete.current.close();
					}}>OK</button>
				</div>
			</Popup>
			{ curTab &&
			<Popup ref={popupCheckRefs}>
				<h2 className="popup-header">References exist</h2>
				<div className="popup-body">Please remove the following references to { curTab.name } first.<br/>{ tabRefs && Object.keys(tabRefs).map(key => <div key={ key }>Tab:{ key }, Field:{ tabRefs[key] }</div>) }</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => {
						setTabRefs(null);
						popupCheckRefs.current.close();
					}}>OK</button>
				</div>
			</Popup> }
			<Popup ref={popupErrNamingEl}>
				<h2 className="popup-header">Input Error</h2>
				<div className="popup-body">Please enter different Element Name.</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => {
						popupErrNamingEl.current.close();
						elNameInputRef.current?.focus();
					}}>OK</button>
				</div>
			</Popup>
			<Popup ref={popupErrNamingTab}>
				<h2 className="popup-header">Input Error</h2>
				<div className="popup-body">Please enter different Tab Name.</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => {
						popupErrNamingTab.current.close();
						tabNameInputRef.current?.focus();
					}}>OK</button>
				</div>
			</Popup>
			{ element && 
			<>
				<Tabbar tabs={ tabs } curTab={ curTab } onSelectTab={ onSelectTab } onAddTab={ onAddTab } status={ tabStatus } canDrag={ true } handleInputChange = { handleInputChange } />
				{ Object.keys(curTab || {}).length == 0 ?
				<div className="builder-basic builder-under-tab">
					<div className="builder-block-wrapper">
						<div className="builder-block">
							<span>Element Name</span><br/>
							<input ref={ elNameInputRef } name="Name" type="text" value={ element.Name || ''} readOnly={ element._id ? true : false } { ...(!element._id && {onChange: handleInputChange}) } />
						</div>
						<div className="builder-block">
							<span>Versions</span><br/>
							<TextList name="versions" cols={1} rows={ (element.versions || []).map(v => [v].flat()) } handleInputChange={ handleInputChange } />
						</div>
						<div className="builder-block">	
							<span>Shortcuts</span><br/>
							<TextList name="shortcuts" cols={2} placeholders={['Name...', 'URL...']} rows={ (element.shortcuts || []).map(v => [v.Name, v.URL]) } handleInputChange={ handleInputChange } />
						</div>
						<div className="builder-block">
							<span>Allowed File Extensions</span><br/>
							{ Object.keys(settings.extensions || {}).map(type => <div className="builder-extensions-wrapper" key={ type }>
								<span>{ type }:</span>
								{ settings.extensions[type].replace(/\s/g, '').split(',').map((ext, i) => <label key={ i }><input name="extensions" type="checkbox" value={ `${type}:${ext}`} checked={ element.extensions?.[type] && element.extensions[type].indexOf(ext) != -1 ? true : false } onChange={ handleInputChange } />{ ext }</label>)}
							</div>) }
						</div>
					</div>
					<div className="builder-save-delete">
						<button className="primary" onClick={ () => saveElement() }>Save</button>
						{ !element._id ?  
						<button className="primary" onClick={ cancelAddElement }>Cancel</button> :
						<button className="primary" onClick={ () => deleteElement() }>Delete Element</button> }
					</div>
				</div> :
				<div className="builder-tab builder-under-tab">
					<div className="builder-block-wrapper">
						<div className="builder-block">
							<span>Tab Name</span><br/>
							<input ref={ tabNameInputRef } name="tab-name" type="text" value={ curTabName || '' } onChange={ handleInputChange }/>
						</div>
						<div className="builder-block">
							<span>Permissions</span><br/>
							<label><input name="tab-permissions" type="radio" value="All" checked={ curTab.data?.Permissions == 'All' } onChange={ handleInputChange }/>All</label>
							<label><input name="tab-permissions" type="radio" value="Edit" checked={ curTab.data?.Permissions == 'Edit' } onChange={ handleInputChange }/>Edit Only</label>
						</div>
						<div className="builder-block">
							<span>Visibility</span><br/>
							<select name="tab-visibility" value={ curTab.data?.Visibility || '1'} onChange={ handleInputChange }>
								<option value="1">All Users</option>
								<option value="2">Admins</option>
								<option value="3">Devs</option>
							</select>
						</div>
						<div className="builder-block">
							<span>Map</span><br/>
							<select name="hotspots" value={ (element.hotspots || []).includes(curTabName) ? 'on' : 'off' } onChange={ handleInputChange }>
								<option value="on">Activated</option>
								<option value="off">Deactivated</option>
							</select>
						</div>
						<div className="builder-block">
							<span>Sort Field</span><br/>
							<input name="tab-sort-name" type="text" value={ curTab.data?.Sort?.[0] === undefined ? 'last_update_time' : (curTab.data.Sort[0] || '') } onChange={ handleInputChange } />
							<select name="tab-sort-direction" value={ curTab.data?.Sort?.[1] || '-1' } onChange={ handleInputChange }>
								<option value="1">1</option>
								<option value="-1">-1</option>
							</select>
						</div>
					</div>
					<div className="builder-fields-wrapper">	{ /* adding Fields */ }
						<Table className="standard-table" rows={ tableRows } columns={ tableColumns } deleteRows={ deleteRow } onDrop={ onDrop }>
							{ (row, action, renderAction, colspan, closeFn) => <RowDetailBuilder colSpan={ colspan } element={ element } tab={ curTab } row={ row } action={ action } renderAction={ renderAction } closeFn={ closeFn } defaultVals={ defaultVals } handleInputChange={ handleInputChange } /> }
						</Table>
					</div>
					<div className="builder-save-delete">
						<button className="primary" onClick={ () => saveElement() }>Save</button>
						{ status == 'adding_tab' ?  
						<button className="primary" onClick={ cancelAddTab }>Cancel</button> :
						<button className="primary" onClick={ () => deleteTab() }>Delete Tab</button> }
					</div>
				</div> }
			</> }
		</div>
		
	)
};

export default Builder;