import axios from 'axios';

export const getFileExtension = (name='') => {
	return /[^.]+$/.exec(name)[0].toLowerCase();
};

export const getAssetType = (name='') => {
	let filetype = '';
	const ext = getFileExtension(name);
	switch (ext) {
		case 'png': case 'jpg': case 'jpeg': case 'gif':
			filetype = 'image';
		break;
		case 'mp4': case 'webm': case 'bk2':
			filetype = 'video'
		break;
		case 'mp3': case 'au':
			filetype = 'audio';
		break;
		case 'zip':
			filetype = 'zip';
		break;
		case 'pdf': case 'txt':
			filetype = 'document';
		break;
		default:
			filetype = 'unknown';

	}
	return filetype;
};

export const getAssetTypeIcon = (name='') => {
	let icon = <img />;
	const filetype = getAssetType(name);
	switch (filetype) {
		case 'image':
			icon = <img src="../assets/icon_image2x.png" />;
		break;
		case 'video':
			icon = <img src="../assets/icon_video2x.png" />;
		break;
		case 'audio':
			icon = <img src="../assets/icon_audio2x.png" />;
		break;
		case 'zip':
			icon = <img src="../assets/icon_zip2x.png" />;
		break;
		case 'document':
			icon = <img src="../assets/icon_text2x.png" />;
		break;
		default:
			icon = <img src="../assets/icon_unknown2x.png" />;

	}
	return icon;
};

export const getMMDDYYYY = (ts) => {
	const date = new Date(ts);
	return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() ;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const getCollection = ( collection, query, sort, callback ) => {
    const postData = new FormData();
    postData.append('coll', collection);
	if(query)
		postData.append('query', JSON.stringify(query));

	postData.append('sort', sort);

	axios.post('/db/read', postData)
	.then(res => {
		callback(res.data);
	})
	.catch(err => {
		console.log('getCollection Error:', err);
	});
};
