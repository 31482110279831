import { useRef, useState, useEffect } from 'react';
import * as Helpers from '../../services/helpers'; 
import './textlist.css';

const TextList = (props) => {
	const [totalRows, setTotalRows] = useState([]);
		
	useEffect(() => {
		props.rows && setTotalRows(props.rows);
	}, [props.rows]);

	const addRow = (e) => {
		const _totalRows = [...totalRows, new Array(props.cols).fill('')];
		setTotalRows(_totalRows);
		props.handleInputChange && props.handleInputChange(props.name, _totalRows);
	};

	const deleteRow = (e, idx) => {
		const _totalRows = totalRows.filter((r, i) => i != idx);
		setTotalRows(_totalRows);
		props.handleInputChange && props.handleInputChange(props.name, _totalRows);
	};

	const changeHandler = (e, rowIdx, colIdx) => {
		const _rows = [...totalRows];
		_rows[rowIdx][colIdx] = e.target.value;
			setTotalRows(_rows);
			props.handleInputChange && props.handleInputChange(props.name, _rows);
	};

	return (
		<div className="textlist-container">
			<div className="add-button-wrapper">
				<button className="add icon" onClick={ addRow } />
			</div>
			<div className="rows-wrapper">
				{ totalRows.map((row, i) => 
				<div key={ i } className="row-wrapper">
					<div className="col-num">{ i + 1 }.</div>
					<div className="col-item-wrapper">
					{ new Array(props.cols).fill('').map((n, j) =>
						<input key={ j } type="text" value={ row[j] || '' } placeholder={ props.placeholders?.[j] || ''} onChange={ (e) => changeHandler(e, i, j) } />
					) }
					</div>
					<button className="remove icon" onClick={ (e) => deleteRow(e, i) } />
				</div>)	}	
			</div>
		</div>
  	)
};

export default TextList;