import { useRef, useState, useEffect, useContext } from 'react';
import './user.css';
import axios from 'axios';
import * as Helpers from '../../services/helpers';
import Popup from '../../components/popup/popup';

const User = (props) => {
	const [user, setUser] = useState({});
	const [popupMsg, setPopupMsg] = useState('');
	const popupErrorRef = useRef();

	const displayNameRef = useRef();
	const curPasswordRef = useRef();
	const passwordRef = useRef();
	
	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		axios.get('/cms/user')
		.then(res => {
			console.log('_____cms/user:', res.data);
			setUser(res.data?.user || {});
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	const handleInputChange = (fieldName, value) => {
		let event = null;

		if (fieldName.target?.name && !value) {	// from onChange
			event = fieldName;
			fieldName = event.target.name;
			value = event.target.value || '';
		}

		if (!fieldName) {
			return;
		}

		const _user = {...user};
		
		switch(fieldName) {
			case 'Validatable': 
				_user.Validatable = event.target.value === 'false' ? false : true;
			break;
		}

		setUser(_user);
	};

	const saveUser = () => {
		const _user = {
			_id: user._id,
			Name: (displayNameRef.current.value || '').trim(),
			pw: curPasswordRef.current.value,
			Password: passwordRef.current.value,
			Validatable: user.Validatable
		};
		
		axios.post('/db/updateUser', {obj: JSON.stringify(_user)})
		.then(res => {
			loadData();
			props.onChangeUser && props.onChangeUser(_user.Name);
		})
		.catch(err => {
			setPopupMsg(<>
				{ (err.response?.data || 'Unknown error: Please try again').split('\n').map((msg, i) => <p key={ i }>{ msg }</p>)}
			</>);
			popupErrorRef.current?.open();
		})
		.finally(() => {
		});
	};

	return (
		<div className="user-container">
			<Popup ref={popupErrorRef}>
				<h2 className="popup-header">Error</h2>
				<div className="popup-body">{ popupMsg }</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => popupErrorRef.current.close() }>OK</button>
				</div>
			</Popup>
			<div className="user-block-wrapper">
				<div className="user-block-column-wrapper">
					<div className="user-block">
						<span>Display Name</span>
						<input ref={ displayNameRef } type="text" defaultValue={ user.Name } />
					</div>
					<div className="user-block">
						<span>Username</span>
						<input type="text" readOnly={ true } defaultValue={ user.Email } />
					</div>
				</div>
				<div className="user-block-column-wrapper">
					<div className="user-block">
						<span>Password</span>
						<input ref={ curPasswordRef } type="password" readOnly={ true } defaultValue={ user.Password || '' } />
					</div>
					<div className="user-block">
						<span>New Password</span>
						<input ref={ passwordRef } type="password" />
					</div>
				</div>
				{ user.Role >= 2 && 
				<div className="user-block-column-wrapper">
					<div className="user-block">
						<span>Validation Privilege</span>
						<div className="user-block-column">
							<label><input name="Validatable" type="radio" value="true" checked={ Boolean(user.Validatable) } onChange={ handleInputChange } />
							Yes</label>
							<label><input name="Validatable" type="radio" value="false" checked={ !Boolean(user.Validatable) } onChange={ handleInputChange } />
							No</label>
						</div>
					</div>
				</div> }
			</div>
			<div className="user-save-delete">
				<button className="primary" onClick={ () => saveUser() }>Save</button>
			</div>
		</div>
	)
};

export default User;