import { useRef, useState, useEffect } from 'react';
import './tabbar.css';

const Tabbar = (props) => {
	const [tabs, setTabs] = useState([]);
	const [curTab, setCurTab] = useState({});
	const [status, setStatus] = useState('');

	useEffect(() => {
		props.tabs && setTabs(props.tabs);
		setCurTab(props.curTab || null);
	}, [props.tabs, props.curTab]);

	useEffect(() => {
		props.status == 'reset' && setStatus('');
	}, [props.status]);

	const selectTab = (tab) => {
		if (status == 'adding') {
			return;
		}
		setCurTab(tab);
		props.onSelectTab && props.onSelectTab(tab);
	};

	const addTab = () => {
		const newTab = {name: 'New_Tab'};
		setStatus('adding');
		// setTabs([...tabs, newTab]);
		setCurTab(newTab);
		props.onAddTab && props.onAddTab(newTab);
	};

	const dragStartHandler = (e, tab) => {
		console.log(e.currentTarget);
		setCurTab(tab);
		selectTab(tab);
	};

	const dropHandler = (tab) => {
		const _tabs = [...tabs];
		const idx1 = _tabs.indexOf(tab);
		const idx2 = _tabs.indexOf(curTab);

		if (idx1 != -1 && idx2 != -1) {
			_tabs.splice(idx2, 1);
			_tabs.splice(idx1, 0, curTab);

			props.handleInputChange && props.handleInputChange('tabOrder', _tabs.map(tab => tab.name));
		}
	};

	const dragOverHandler = (e, tab) => {
		e.preventDefault();
	};

	const dragEndHandler = (e) => {
		
	};

	return (
		<div className="tabbar-container">
			<ul className={ status }>
				{ tabs.map((tab, i) => 
				<li key={ i } draggable={ props.canDrag } className={ `tab ${curTab && curTab.name == tab.name ? 'active' : ''}` } onClick={ () => selectTab(tab) } { ...(props.canDrag && {draggable: true, onDragStart: (e) => dragStartHandler(e, tab), onDrop: () => dropHandler(tab), onDragOver: e => dragOverHandler(e, tab), onDragEnd: dragEndHandler}) }>
					{ tab.name }
				</li>) }
				{ props.onAddTab && status != 'adding' &&
				<button className="add image" onClick={ addTab } /> }
			</ul>
		</div>
  	)
};

export default Tabbar;