// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabbar-container ul {
	display: flex;
    margin: 0;
	padding: 0;
    list-style: none;
	font-size: 0.9rem;
	text-align: center;
	flex-wrap: wrap;
	background-color: var(--background-color);
}

.tabbar-container ul > * {
	margin: 0.5rem 0 0 0.3rem;
    height: 3rem;
}

.tabbar-container ul > button {
	padding: 1.2rem;
	background-size: 80%;
}

.tabbar-container li {
	display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    width: 10%;
    color: var(--button-primary-text-color);
	background-color: var(--button-tertiary-down-color);
}

.tabbar-container ul.adding li:not(.active) {
	opacity: 0.5;
	pointer-events: none;
}

.tabbar-container .tab.active {
	font-weight: bold;
	color: var(--tabbar-active-color);
	background-color: var(--background-secondary-color);
}`, "",{"version":3,"sources":["webpack://./public/components/tabbar/tabbar.css"],"names":[],"mappings":"AAAA;CACC,aAAa;IACV,SAAS;CACZ,UAAU;IACP,gBAAgB;CACnB,iBAAiB;CACjB,kBAAkB;CAClB,eAAe;CACf,yCAAyC;AAC1C;;AAEA;CACC,yBAAyB;IACtB,YAAY;AAChB;;AAEA;CACC,eAAe;CACf,oBAAoB;AACrB;;AAEA;CACC,aAAa;IACV,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;IAC9B,+BAA+B;IAC/B,UAAU;IACV,uCAAuC;CAC1C,mDAAmD;AACpD;;AAEA;CACC,YAAY;CACZ,oBAAoB;AACrB;;AAEA;CACC,iBAAiB;CACjB,iCAAiC;CACjC,mDAAmD;AACpD","sourcesContent":[".tabbar-container ul {\n\tdisplay: flex;\n    margin: 0;\n\tpadding: 0;\n    list-style: none;\n\tfont-size: 0.9rem;\n\ttext-align: center;\n\tflex-wrap: wrap;\n\tbackground-color: var(--background-color);\n}\n\n.tabbar-container ul > * {\n\tmargin: 0.5rem 0 0 0.3rem;\n    height: 3rem;\n}\n\n.tabbar-container ul > button {\n\tpadding: 1.2rem;\n\tbackground-size: 80%;\n}\n\n.tabbar-container li {\n\tdisplay: flex;\n    justify-content: center;\n    align-items: center;\n    border-top-left-radius: 0.5rem;\n    border-top-right-radius: 0.5rem;\n    width: 10%;\n    color: var(--button-primary-text-color);\n\tbackground-color: var(--button-tertiary-down-color);\n}\n\n.tabbar-container ul.adding li:not(.active) {\n\topacity: 0.5;\n\tpointer-events: none;\n}\n\n.tabbar-container .tab.active {\n\tfont-weight: bold;\n\tcolor: var(--tabbar-active-color);\n\tbackground-color: var(--background-secondary-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
