import { useRef, useState, useEffect } from 'react';
import './row_detail.css';
import * as Helpers from '../../services/helpers'; 
import axios from 'axios';
import Popup from '../popup/popup';

const RowDetail = (props) => {
	const [rowData, setRowData] = useState({});
	const [fieldType, setFieldType] = useState('');
	const [options, setOptions] = useState({});
	const popupErrNameRef = useRef();

	const fieldOptions = {
		"Single-line Input": {'Input Type':["text", "number"], 'Character Limit':0, "Default": ''},
		"Multi-line Input": {'Input Type':["plain", "formatted"], 'Character Limit':0, "Default": ''},
		"Text List": {"Columns":1, "Row Limit":0, "Character Limit":0},
		"Multiple Choice": {"Type": ["Dropdown", "Checkbox"], "Values": "", "Default": ''},
		"Single Asset": {"Types": []},
		"Asset List": {"Types": [], "Limit":0},
		"Object": {"Type": '0', "Limit":0},
		"Date Input": {"Default": 'Today'},
		"Time Input": {"Default": 'Now'},
		// "BC3 Story": {},
		"Spacer": {}
	};

	let isOptTypeChanged = false;
		
	useEffect(() => {
		setRowData(props.row || props.defaultVals);
		setFieldType(props.row?.type || 'Single-line Input');
		isOptTypeChanged = false;
		console.log('*.*.*.*row_detail_builder:', props.row);
	}, [props.row]);

	const handleInputChange = (evt) => {
		
		let fieldName = evt.target?.name || '';

		if (!fieldName) {
			return;
		}

		const _rowData = {...rowData};
		let subFieldName = '';
		let value = evt.target.value || '';

		if (/-/.test(fieldName)) {
			const _fields = fieldName.split('-');
			fieldName = _fields[0];
			subFieldName = _fields[1];
		}

		switch(fieldName) {
			case 'container':
			case 'options':
				if (!_rowData[fieldName]) {
					_rowData[fieldName] = {};
				}

				if (evt.target?.type == 'checkbox') {
					_rowData[fieldName][subFieldName] = _rowData[fieldName][subFieldName] || [];
					const idx = _rowData[fieldName][subFieldName].indexOf(value);
					if (idx != -1) {
						_rowData[fieldName][subFieldName].splice(idx, 1);
					} else {
						_rowData[fieldName][subFieldName].push(value);
					}
				} else {
					_rowData[fieldName][subFieldName] = value;
				}
			break;
			case 'type':
				_rowData[fieldName] = value;
				isOptTypeChanged = true;
				setFieldType(value);
			break;
			default:
				_rowData[fieldName] = value;
		}

		setRowData(_rowData);
		// props.handleInputChange && props.handleInputChange();
	};

	const saveField = () => {
		if (!rowData || !rowData.name) {
			popupErrNameRef.current.open();
			return;
		}
		const _rowData = { ...rowData };
		const fields = [...(props.tab.fields || [])];
		if (props.row) {
			let curFieldIdx = fields.findIndex(field => field._id == props.row._id);
			fields[curFieldIdx] = _rowData;
		} else {
			fields.push(_rowData);
		}
		
		props.handleInputChange('tab-fields', fields);
		props.closeFn();
	};

	const renderOptions = () => {
		const origOptions = isOptTypeChanged ? {} : (rowData.options || {});
		isOptTypeChanged = false;
		const _options = fieldOptions[fieldType] || {};
		let renderVals = [];

		for (const [key, val] of Object.entries(_options)) {
			if (fieldType == 'Object' && key == 'Type') {
				renderVals.push(
				<div key={ key } className="opt-row">
					<span>{ key }</span>
					<select name="options-Type" value={ origOptions[key] || ''} onChange={ handleInputChange }>
						{ (props.element.tabOrder || []).map((tabName, i) => <option key={ i } value={ tabName }>{ tabName }</option>) }
					</select>
				</div> );
			} else if (/(Single Asset|Asset List)/.test(fieldType) && key == 'Types') {
				renderVals.push(
				<div key = {key} className="opt-row">
					<span>{ key }</span>
					{ (Object.keys(props.element?.extensions || {}) || []).map((assetType, i) => <label key={ i }><input name="options-Types" type="checkbox" checked={ (origOptions[key] || []).includes(assetType) } value={ assetType } onChange={ handleInputChange } />{ assetType }</label>) }
				</div> );
			} else if (Array.isArray(val)) {
				renderVals.push(
				<div key={ key } className="opt-row">
					<span>{ key }</span>
					<select name={ `options-${key}` } value={ origOptions[key] || val[0] } onChange={ handleInputChange }>
						{ val.map((opt, i) => <option key={ i } value={ opt }>{ opt }</option>) }
					</select>
				</div> );
			} else {
				renderVals.push(
				<div key={ key } className="opt-row">
					<span>{ key }</span>
					<input type="text" name={ `options-${key}` } value={ origOptions[key] || val } onChange={ handleInputChange } />
				</div> );
			}
		}
		
		return renderVals;
	};

	return (
		<>
			<td className='dragme-cell'>{ props.action == 'EDIT' && props.renderAction('__dragme_tbody', props.row) }</td>
			<td className="cell row-detail-cell" colSpan={ props.colSpan }>
				<div className="row-detail-fields">
					<Popup ref={popupErrNameRef}>
						<h2 className="popup-header">Input Error</h2>
						<div className="popup-body">Please enter Name.</div>
						<div className="popup-footer">
							<button className="primary" onClick={ () => {
								popupErrNameRef.current.close();
							}}>OK</button>
						</div>
					</Popup>
					<div className="row-detail-field-wrapper">
						<div className="field-type">
							<select name="type" value={ rowData.type || '' } onChange={ handleInputChange } >
								{ Object.keys(fieldOptions).map((type, i) =>
								<option key={ i } value={ type }>{ type }</option>
								) }
							</select>
						</div>
						<div className="field-name">
							<input name="name" type="text" value={ rowData.name || '' } onChange={ handleInputChange } />
						</div>
						<div className="field-width">
							<input name="container-width" type="text" value={ rowData.container?.width || '' } onChange={ handleInputChange } />
						</div>
						<div className="field-margin">
							<input name="container-margin" type="text" value={ rowData.container?.margin || '' } onChange={ handleInputChange } />
						</div>
						<div className="field-display">
							<select name="container-display" value={ rowData.container?.display || '' } onChange={ handleInputChange }>
								<option value="block">block</option>
								<option value="inline-block">inline-block</option>
							</select>
						</div>
						<div className="field-table">
							<select name="table" value={ rowData.table || ''} onChange={ handleInputChange }>
								<option value="Hidden">Hidden</option>
								<option value="Shown">Shown</option>
								<option value="Alphabetical">Alphabetical</option>
								<option value="Numerical">Numerical</option>
								{/* <option value="Contains">Contains</option> */}
							</select>
						</div>
						<div className="break"></div>
						<div className="field-options">
							{ renderOptions() }
						</div>
					</div>
				</div>
			</td>
			<td className="action-cell">
				<div className="row-detail-save-btns">
					<button className="primary" onClick={ saveField }>{ props.action == 'EDIT' ? 'Update' : 'Add' }</button>
					<button className="primary" onClick={ props.closeFn }>Cancel</button>
				</div>
			</td>
		</>
  	)
};

export default RowDetail;