// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon_view_code2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-container .action-cell button.add {
	display: none;
}

.dev-container .action-cell button.edit {
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.dev-container .dev-view-title {
	font-weight: bold;
}

.dev-container .messing-with-db-container {
	margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--background-color);
}

.dev-container .messing-with-db-wrapper {
	margin: 0.25rem;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: var(--background-secondary-color);
}

.dev-container .messing-with-db-wrapper > span {
	display: block;
	font-size: 0.95rem;
	font-weight: bold;
}

.dev-container .messing-with-db > * {
	margin-left: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./public/views/dev/dev.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,yDAAwD;AACzD;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,cAAc;IACX,eAAe;IACf,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;CACC,eAAe;CACf,aAAa;CACb,qBAAqB;CACrB,mDAAmD;AACpD;;AAEA;CACC,cAAc;CACd,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,oBAAoB;AACrB","sourcesContent":[".dev-container .action-cell button.add {\n\tdisplay: none;\n}\n\n.dev-container .action-cell button.edit {\n\tbackground-image: url(../../assets/icon_view_code2x.png);\n}\n\n.dev-container .dev-view-title {\n\tfont-weight: bold;\n}\n\n.dev-container .messing-with-db-container {\n\tmargin: 0.5rem;\n    padding: 0.5rem;\n    border-radius: 0.5rem;\n    background-color: var(--background-color);\n}\n\n.dev-container .messing-with-db-wrapper {\n\tmargin: 0.25rem;\n\tpadding: 1rem;\n\tborder-radius: 0.5rem;\n\tbackground-color: var(--background-secondary-color);\n}\n\n.dev-container .messing-with-db-wrapper > span {\n\tdisplay: block;\n\tfont-size: 0.95rem;\n\tfont-weight: bold;\n}\n\n.dev-container .messing-with-db > * {\n\tmargin-left: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
