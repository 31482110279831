// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-dialog {
	border-radius: 0.65rem;
	box-shadow: 0.5rem 0.5rem 0.5rem rgba(0,0,0,0.15);
	min-width: 12rem;
	background-color: var(--quaternary-color);
    border: solid 1px rgba(0,0,0,0.25);
	padding: 1.5rem;
}
.popup-header {
	font-size: 1.25rem;
	font-weight: bold;
	text-align: center;
}
.popup-footer {
	display: flex;
	justify-content: space-evenly;
	margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./public/components/popup/popup.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,iDAAiD;CACjD,gBAAgB;CAChB,yCAAyC;IACtC,kCAAkC;CACrC,eAAe;AAChB;AACA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,kBAAkB;AACnB;AACA;CACC,aAAa;CACb,6BAA6B;CAC7B,gBAAgB;AACjB","sourcesContent":[".popup-dialog {\n\tborder-radius: 0.65rem;\n\tbox-shadow: 0.5rem 0.5rem 0.5rem rgba(0,0,0,0.15);\n\tmin-width: 12rem;\n\tbackground-color: var(--quaternary-color);\n    border: solid 1px rgba(0,0,0,0.25);\n\tpadding: 1.5rem;\n}\n.popup-header {\n\tfont-size: 1.25rem;\n\tfont-weight: bold;\n\ttext-align: center;\n}\n.popup-footer {\n\tdisplay: flex;\n\tjustify-content: space-evenly;\n\tmargin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
