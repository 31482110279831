import { useRef, useState, useEffect } from 'react';
import './pagination.css';

const Pagination = (props) => {
	const { active, total, page, step, onClickHandler, setPageSize } = props;
  
	const showingNumbers = step * 2 + 1;
	let startNumber = 2;
	let startArrayNumber = props.step;
  
	let needStartDots = false;
	let needEndDots = false;
  
	if (active > step) {
	  startArrayNumber = active - step;
  
	  needStartDots = active > step + startNumber ? true : false;
	}
  
	if (total > showingNumbers) {
	  {
		needEndDots = total > active + step + 1 ? true : false;
  
		if (total < active + step + 1) {
		  startArrayNumber = total - showingNumbers;
		}
	  }
	}
  
	let contentNumber;
  
	return (
		<div className="pagination-container">
			<div>
				<span>Items per page</span>
				<select	className="page-size-select" value={ page.size } onChange={ (e) => { setPageSize(Number(e.target.value)) } }>
					{ page.sizes.map((pageSize) => (
					<option key={ pageSize } value={ pageSize }>
						{ pageSize }
					</option>
					)) }
				</select>
			</div>
			<ul className="pagination">
				{active > 1 ? (
				<li
					className="page-item prev arrow-icon"
					onClick={() => onClickHandler(active - 1)}
				></li>
				) : (
				<li className="page-item prev arrow-icon disabled"></li>
				)}
				{total > showingNumbers + startNumber ? (
				<>
					<li
					onClick={(e) => onClickHandler(e.currentTarget.textContent)}
					className={`page-item ${active === 1 && "active"}`}
					>
					1
					</li>
		
					{needStartDots && <span>...</span>}
					{new Array(showingNumbers).fill(0).map((e, i) => (
					<li
						key={i++}
						{...(contentNumber = needStartDots
						? startArrayNumber
						: startNumber)}
						{...startNumber++}
						{...startArrayNumber++}
						className={`page-item ${active === contentNumber && "active"}`}
						onClick={(e) => onClickHandler(e.currentTarget.textContent)}
					>
						{contentNumber}
					</li>
					))}
					{needEndDots && <span>...</span>}
					<li
					className={`page-item ${active === total && "active"}`}
					onClick={(e) => onClickHandler(e.currentTarget.textContent)}
					>
					{total}
					</li>
				</>
				) : (
				((startArrayNumber = 1),
				new Array(total).fill(0).map((e, i) => (
					<li
					key={i++}
					className={`page-item ${active === startArrayNumber && "active"}`}
					onClick={(e) => onClickHandler(e.currentTarget.textContent)}
					>
					{startArrayNumber++}
					</li>
				)))
				)}
				{active < total ? (
				<li
					className="page-item next arrow-icon"
					onClick={() => onClickHandler(active + 1)}
				></li>
				) : (
				<li className="page-item next arrow-icon disabled"></li>
				)}
			</ul>
		</div>
	);
};

export default Pagination;