import { useRef, useState, useEffect } from 'react';
import './row_detail.css';
import * as Helpers from '../../services/helpers'; 
import axios from 'axios';

const RowDetail = (props) => {
	const [collectionInfo, setCollectionInfo] = useState('');

	useEffect(() => {
		props.row && axios.get('/dev/readCollection?t=' + props.row.Name)
		.then(res => {
			try {
				setCollectionInfo(JSON.stringify(res.data));
			} catch {}
		})
		.catch(err => {
			console.log('Error:', err);
		});
	}, [props.row]);

	return (
		<>
			{/* <td className='checkall-cell'>{ props.action == 'EDIT' && props.renderAction('__checkall_tbody', props.row) }</td> */}
			<td className="cell row-detail-cell" colSpan={ props.colSpan }>
				<div className="row-detail-fields">
					<div className="row-detail-field-wrapper">
						<div className="dev-view-title">{ props.row.Name }</div>
						<div className="dev-view-code">{ collectionInfo }</div>

					</div>
				</div>
			</td>
			<td className="action-cell">
				<div className="row-detail-save-btns">
					<button className="primary" onClick={ props.closeFn }>Cancel</button>
				</div>
			</td>
		</>
  	)
};

export default RowDetail;