// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon_clone2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
	display: flex;
    flex-direction: column;
    padding-top: 3.5rem;
    flex-grow: 1;
    font-size: 0.85rem;
	font-weight: bold;
}

.sidebar-container .sidebar-item-wrapper {
	display: flex;
    justify-content: space-between;
    border-top: solid 0.15rem var(--border-color);
}
.sidebar-container.adding .sidebar-item-wrapper:not(.active) {
	opacity: 0.5;
	pointer-events: none;
}

.sidebar-container .sidebar-item-wrapper > * {
	padding: 0.6rem 1rem;
}

.sidebar-container .sidebar-item {
	flex-grow: 1;
}

.sidebar-container .sidebar-item-wrapper:last-child {
	border-bottom: solid 0.15rem var(--border-color);
}
.sidebar-container .sidebar-item-wrapper:active, .sidebar-container .sidebar-item-wrapper.active {
	color: var(--sidebar-active-color);
	background-image: linear-gradient(90deg, var(--sidebar-active-color) 0.3rem, transparent 0);
	background-color: var(--background-secondary-color);
}

.sidebar-container:not(.editable) .clone {
	display: none;
}

.sidebar-container.editable .clone {
	width: 2rem;
	height: 2rem;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.sidebar-container.editable > button.add {
	margin-left: auto;
    margin-right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./public/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;CACC,aAAa;IACV,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;IACV,8BAA8B;IAC9B,6CAA6C;AACjD;AACA;CACC,YAAY;CACZ,oBAAoB;AACrB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gDAAgD;AACjD;AACA;CACC,kCAAkC;CAClC,2FAA2F;CAC3F,mDAAmD;AACpD;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,yDAAsD;AACvD;;AAEA;CACC,iBAAiB;IACd,oBAAoB;AACxB","sourcesContent":[".sidebar-container {\n\tdisplay: flex;\n    flex-direction: column;\n    padding-top: 3.5rem;\n    flex-grow: 1;\n    font-size: 0.85rem;\n\tfont-weight: bold;\n}\n\n.sidebar-container .sidebar-item-wrapper {\n\tdisplay: flex;\n    justify-content: space-between;\n    border-top: solid 0.15rem var(--border-color);\n}\n.sidebar-container.adding .sidebar-item-wrapper:not(.active) {\n\topacity: 0.5;\n\tpointer-events: none;\n}\n\n.sidebar-container .sidebar-item-wrapper > * {\n\tpadding: 0.6rem 1rem;\n}\n\n.sidebar-container .sidebar-item {\n\tflex-grow: 1;\n}\n\n.sidebar-container .sidebar-item-wrapper:last-child {\n\tborder-bottom: solid 0.15rem var(--border-color);\n}\n.sidebar-container .sidebar-item-wrapper:active, .sidebar-container .sidebar-item-wrapper.active {\n\tcolor: var(--sidebar-active-color);\n\tbackground-image: linear-gradient(90deg, var(--sidebar-active-color) 0.3rem, transparent 0);\n\tbackground-color: var(--background-secondary-color);\n}\n\n.sidebar-container:not(.editable) .clone {\n\tdisplay: none;\n}\n\n.sidebar-container.editable .clone {\n\twidth: 2rem;\n\theight: 2rem;\n\tbackground-image: url('../../assets/icon_clone2x.png');\n}\n\n.sidebar-container.editable > button.add {\n\tmargin-left: auto;\n    margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
