import { useRef, useState, useEffect, useContext } from 'react';
import './cms.css';
import axios from 'axios';
import * as Helpers from '../../services/helpers';
import TextList from "../../components/textlist/textlist";

const Cms = (props) => {
	const [settings, setSettings] = useState({});

	const defaultVals = {
		backup: 'None',
		extensions: {
			'Image': 'jpg, jpeg, png',
			'Video': 'mp4, webm, wmv, bk2',
			'Document': 'pdf'
		},
		imageMaxDimensions: {
			'width': 0,
			'height': 0
		},
		thumbDimensions: {
			'width': 0,
			'height': 0
		},
		pageRowsInMedia: 10
	};

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		Helpers.getCollection('settings', '', '', settingsData => {
			const _settings = {...defaultVals, ...(settingsData[0] || {})};
			setSettings(_settings);
		});
	};

	const handleInputChange = (fieldName, value) => {
		let event = null;
		let subFieldName = '';

		if (fieldName.target?.name && !value) {	// from onChange
			event = fieldName;
			fieldName = event.target.name;
			value = event.target.value || '';
		}

		if (/-/.test(fieldName)) {
			const _fields = fieldName.split('-');
			fieldName = _fields[0];
			subFieldName = _fields[1];
		}

		if (!fieldName) {
			return;
		}

		const _settings = {...settings};
		
		switch(fieldName) {
			case 'thumbDimensions': 
			case 'imageMaxDimensions':
				_settings[fieldName][subFieldName] = value;
			break;
			case 'extensions':
				_settings[fieldName] = {};
				for (const [key, val] of value) {
					_settings[fieldName][key] = val;
				}
			break;
			default:
				_settings[fieldName] = value;
		}

		setSettings(_settings);
	};

	const loadDefaultExtensions = () => {
		const _extensions = {...defaultVals.extensions};
		setSettings({...settings, extensions: _extensions});
	};

	const saveSettings = () => {
		axios.post('/dev/saveSettings', {settings: JSON.stringify(settings)})
		.then(res => {
			saveTimer = setTimeout(loadData, 1000);	// due to slow dynamoDB
			// loadData();
		})
		.catch(err => {
			console.log('Error:', err);
		})
		.finally(() => {
		});
	};

	return (
		<div className="cms-container">
			<div className="cms-block-wrapper">
				<div className="cms-block-column-wrapper">
					<div className="cms-block">
						<span>Database Backup</span>
						<select name="backup" className="cms-backup-frequency" value={ settings.backup || '' } onChange={ handleInputChange }>
							<option>None</option>
							<option>Daily</option>
							<option>Weekly</option>
						</select>
					</div>
					<div className="cms-block">
						<span>Limit the amount of media assets per page in Media Library</span>
						<input name="pageRowsInMedia" type="number" value={ settings.pageRowsInMedia || 0 } onChange={ handleInputChange } />
							
					</div>
				</div>
				<div className="cms-block">
					<span style={ {display: 'inline'} }>Allowed File Extensions</span>
					<button className="tertiary" onClick={ loadDefaultExtensions }>Load Defaults</button>
					<TextList name="extensions" cols={ 2 } rows={ Object.entries(settings.extensions || {}) } placeholders={ ['Type...', 'Comma separated extensions...'] } handleInputChange={ handleInputChange } />
				</div>
				<div className="cms-block-column-wrapper">
					<div className="cms-block">
						<span>Image Thumbnail</span>
						<div className="cms-block-row">
							<label>Width:
								<input name="thumbDimensions-width" type="number" value={ settings.thumbDimensions?.width || 0 } onChange={ handleInputChange } />
							</label>
							<label>Height:
								<input name="thumbDimensions-height" type="number" value={ settings.thumbDimensions?.height || 0 } onChange={ handleInputChange } />
							</label>
						</div>
					</div>
					<div className="cms-block">
						<span>Maximum Image Dimensions</span>
						<div className="cms-block-row">
							<label>Width:
								<input name="imageMaxDimensions-width" type="number" value={ settings.imageMaxDimensions?.width || 0 } onChange={ handleInputChange } />
							</label>
							<label>Height:
								<input name="imageMaxDimensions-height" type="number" value={ settings.imageMaxDimensions?.height || 0 } onChange={ handleInputChange } />
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="cms-save-delete">
				<button className="primary" onClick={ () => saveSettings() }>Save</button>
			</div>
		</div>
	)
};

export default Cms;