import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/authProvider";
import axios from 'axios';
import './users.css';
import * as Helpers from '../../services/helpers';
import Popup from '../../components/popup/popup';
import Table from "../../components/table/table";
import RowDetailUser from "../../components/row_detail/row_detail_user";

const Users = (props) => {
	const { auth, setAuth } = useContext(AuthContext);
	const [tableRows, setTableRows] = useState([]);
	const [elements, setElements] = useState({});
	const [popupMsg, setPopupMsg] = useState('');

	const popupRef = useRef();
	
	const roles = ['Inactive', 'User', 'Admin', 'Dev'];
	const tableColumns = [
		{ header: 'Username', width: '15%', className: 'cell first-col', renderFn: (row) => row.Email, sortable: true, searchFn: (row) => row.Email },
		{ header: 'Name', width: '15%', className: 'cell', renderFn: (row) => row.Name, searchFn: (row) => row.Name, sortable: true },
		{ header: 'Elements', width: '20%', className: 'cell', renderFn: (row) => (row.Elements || []).map(el => <div key={ el } className="item">{ elements[el] }</div>), searchFn: (row) => (row.Elements || []).map(el => elements[el]).join() },
		{ header: 'Role', width: '10%', className: 'cell', renderFn: (row) => roles[row.Role], sortable: true, searchFn: (row) => roles[row.Role] },
		{ header: 'Validated', width: '20%', className: 'cell last-col', renderFn: (row) => row.Validation && row.Validation.code != 'validated' && row.Validation.lastIssued ? 'No, since ' + new Date(row.Validation.lastIssued).toLocaleString() : row.Validation && row.Validation.code == 'validated' ? 'Yes' : row.SkipValidation ? 'No need to validate' : 'Need to send validation' },
		{ header: '__action', width: '20%', className: 'action-cell', customButton: {className: 'validate', fn: (row) => validateUser(row) } },
	];

	useEffect(() => {
		Helpers.getCollection('users', '', '', rows => {
			console.log('Users!!!:', rows);
			axios.get('/cms/users')
			.then(res => {
				setTableRows(rows);

				const els = {};
				if (Array.isArray(res.data?.elements)) {
					res.data.elements.forEach(el => {
						els[el[0]] = el[1];
					});
				}
				setElements(els);
			})
			.catch(err => {
				console.log('Error:', err);
			});
		});
	}, []);

	const validateUser = (row) => {
		const id = row._id;
		axios.post('/validateUser', {id: id})
		.then(res => {
			setPopupMsg('Notification email sent successfully');
			popupRef.current?.open();
		})
		.catch(err => {
			setPopupMsg('Notification email failed to send: ' + err);
			popupRef.current?.open();
		});
	};

	const updateTable = () => {
		Helpers.getCollection('users', '', '', rows => {
			console.log('rows after edit submit:', rows);
			setTableRows(rows);
		});
	};

	const deleteRow = (rows) => {
		const row = rows[0];
		axios.post('/db/delete', {collection: 'users', id: row._id})
		.then(res => {
			setTableRows(tableRows.filter(row => !rows.includes(row)));
			console.log('row deleted!', row);
		})
		.catch(err => {
			console.log('Error:', err);
		});
	};

	return (
		<div id="users-container">
			<Popup ref={popupRef}>
				<h2 className="popup-header">Validation</h2>
				<div className="popup-body">{ popupMsg }</div>
				<div className="popup-footer">
					<button className="primary" onClick={ () => popupRef.current.close() }>OK</button>
				</div>
			</Popup>
			<Table className="standard-table" rows={ tableRows } columns={ tableColumns } deleteRows={ deleteRow }>
				{ (row, action, renderAction, colspan, closeFn) => <RowDetailUser colSpan={ colspan } elements={ elements } updateTable={ updateTable } row={ row } action={ action } renderAction={ renderAction } closeFn={ closeFn } /> }
			</Table>
		</div>
	)
}

export default Users