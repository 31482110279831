import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation, navigate } from "react-router-dom";
import axios from 'axios';
import './reset.css';

const Reset = () => {
	const [username, setUsername] = useState('');
	const [pwd, setPwd] = useState('');
	const [pwd2, setPwd2] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [status, setStatus] = useState('');
	const navigate = useNavigate();
	
	useEffect(() => {
        setErrMsg('');
    }, [username, pwd, pwd2]);

	const handleSubmit = e => {
        e.preventDefault();
		const formData = new FormData(e.target);
		if (pwd && pwd != pwd2) {
			setErrMsg('Password and Re-type Password do not match. Please try again');
			return;
		}
        axios.post('/cms/reset', formData)
		.then(res => {
			res.data?.status && setStatus(res.data.status);
			res.data?.message && setErrMsg(res.data.message);
		})
		.catch (err => {
			console.log('post error:', err);
		});
	}
	
	return (
		<div className="reset-container">
			<div className="col-left">
				<div className="row">
					<img className="logo" src="../assets/logo_white2x.png" />
				</div>
				<div className="row">
					Content Management System
				</div>
			</div>
			<div className="col-right">
				<div className="err-msg" dangerouslySetInnerHTML={{__html: errMsg}} />
				<form id="reset-box" onSubmit={handleSubmit}>
					<input type="text" name="Email" className="grid-item username" value={username} onChange={(e) => setUsername(e.target.value)}placeholder="Username (valid email address)" readOnly={status == 'step2'} />
					{ status == 'step2' && <>
						<input type="text" name="Code" className="grid-item code" defaultValue="" placeholder="Validation Code (check your email)" />
						<input type="password" name="Pwd" className="grid-item password" value={pwd} onChange={(e) => setPwd(e.target.value)}placeholder="Password" />
						<input type="password" name="Pwd2" className="grid-item password2" value={pwd2} onChange={(e) => setPwd2(e.target.value)} placeholder="Re-type Password" />
						<input type="hidden" name="status" value={status} /></>
					}
					<hr />
					<button className="grid-item go-back primary" onClick={e => { navigate(-1) }}>Back</button>
					<button type="submit" className="grid-item submit primary">{status == 'step2' ? 'Submit' : 'Next'}</button>
					
				</form>
			</div>
		</div>
	)
};

export default Reset;